import { faEllipsis, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { Anchor, Button, Flex, Space } from '@mantine/core';
import { AffiliateItem, CommonTableColumn } from 'types';
import { useAffiliateList, useDeleteAffiliate } from 'apis/AffiliateApis';
import IconFA from 'components/common/IconFA';
import FilterButton from 'components/MantineUI/Buttons/FilterButton';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { CommonTable, TableSearchBox } from 'components/MantineUI/CommonTable';
import SortButton from 'components/MantineUI/CommonTable/SortButton';
import PageLayout from 'components/MantineUI/PageLayout';
import { t } from 'i18next';
import React, { ReactNode, useState } from 'react';
import { Link } from 'react-router-dom';
import { dateFormatter, dateTimeFormatter } from 'helpers/utils';
import StatusBadge from 'components/MantineUI/StatusBadge';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import { usePaginationParams } from 'hooks/usePaginationParams';
import { useURLParams } from 'hooks/useURLParams';
import AddAffiliateModal from './AddAffiliateModal';
import DeleteWarningModal from 'components/common/DeleteWarningModal';

const Affiliates = () => {
  // ### CONSTANTs
  const { params }: any = useURLParams();

  const { page, limit, onPaginationChange } = usePaginationParams();
  const [selectedAffiliate, setSelectedAffilate] = useState<
    AffiliateItem | undefined
  >();
  const [deleteAffiliate, setDeleteAffiliate] = useState<
    AffiliateItem | undefined
  >();
  const { mutate: onDeleteAffiliate, isPending: deleting } =
    useDeleteAffiliate();
  const { data, isLoading } = useAffiliateList({
    ...params,
    page,
    limit,
  });
  const affiliates: AffiliateItem[] = data?.data?.results || [];
  const total = data?.data?.total;
  //   account_name
  // affiliate_code
  // commission_rate
  // extra_discount
  // ### FUNCTIONs
  // ### RENDERs
  const onDelete = () => {
    onDeleteAffiliate(deleteAffiliate?._id, {
      onSuccess: () => {
        setDeleteAffiliate(undefined);
      },
    });
  };

  const columns: CommonTableColumn[] = [
    {
      accessor: 'name',
      Header: t('affiliates.account_display_name'),
      Cell: (row: AffiliateItem) => (
        <Anchor size="sm" component={Link} to={'/affiliates/' + row?._id}>
          {row?.display_name}
        </Anchor>
      ),
    },
    {
      accessor: 'affiliate_code',
      Header: t('affiliates.affiliate_code'),
      Cell: (row: AffiliateItem) => row?.code,
    },
    {
      accessor: 'commission_rate',
      Header: t('affiliates.commission_rate'),
      Cell: (row: AffiliateItem) =>
        row.commission_rate ? (row.commission_rate || 0) * 100 + '%' : '--',
    },
    {
      accessor: 'extra_discount',
      Header: t('affiliates.extra_discount'),
      Cell: (row: AffiliateItem) => (row.discount ? row.discount + '%' : '--'),
    },
    {
      accessor: 'created',
      Header: t('general.created_date'),
      Cell: (row: AffiliateItem) => dateFormatter(row.created_on),
    },
    {
      accessor: 'status',
      Header: t('general.status'),
      Cell: (row: AffiliateItem) =>
        row.status && <StatusBadge status={row.status} />,
    },
    {
      accessor: 'actions',
      Cell: (row) => (
        <DropdownButton
          button={
            <Button size="xs" variant="subtle" color="gray">
              <IconFA icon={faEllipsis} />
            </Button>
          }
          dropdownData={[
            {
              label: t('general.edit'),
              key: 'edit',
              onClick: () => setSelectedAffilate(row),
            },
            { isDivider: true },
            {
              key: 'delete',
              color: 'red',
              label: t('general.delete'),
              onClick: () => setDeleteAffiliate(row),
            },
          ]}
        />
      ),
    },
  ];

  const title = t('affiliates.affiliates');
  return (
    <PageLayout title={title} breadCrumds={[{ title: title }]}>
      <SectionWrapper>
        <SectionHeader
          title={title}
          subtitle={t('affiliates.affiliates_decs')}
          // actionButtons={
          //   <Button
          //     onClick={() => setSelectedAffilate({})}
          //     leftSection={<IconFA icon={faPlus} />}
          //   >
          //     {t('affiliates.add_affiliate')}
          //   </Button>
          // }
        />
        <SectionBody>
          <Flex gap={'sm'} className="mb-3">
            <TableSearchBox searchParams="name" />
            <FilterButton />
            <SortButton />
          </Flex>
          <CommonTable
            columns={columns}
            data={affiliates}
            pagination={{
              page,
              limit,
              onPaginationChange,
              total,
            }}
          />
        </SectionBody>
        <AddAffiliateModal
          opened={!!selectedAffiliate}
          onClose={() => setSelectedAffilate(undefined)}
          data={selectedAffiliate}
        />
        <DeleteWarningModal
          name={deleteAffiliate?.display_name}
          onShow={!!deleteAffiliate}
          onClose={() => setDeleteAffiliate(undefined)}
          onDelete={onDelete}
          isLoading={deleting}
        />
      </SectionWrapper>
    </PageLayout>
  );
};
export default Affiliates;
