import { AddressInfo, AddressTypeEnum } from 'types';
import { t } from 'i18next';
import {
  Button,
  Flex,
  Grid,
  NativeSelect,
  Switch,
  TextInput,
} from '@mantine/core';
import { useEffect, useState } from 'react';
import { useForm, yupResolver } from '@mantine/form';
import * as yup from 'yup';
import validator from 'validator';
import { PHONE_NO_REQUIRE_ERROR_MESSAGE } from 'constants/constants';
import { SectionBody, SectionHeader, SectionWrapper } from '../CommonSection';
import AddressInfoComponent from '../AddressInfoComponent';
import AddressInput from '../Inputs/AddressInput';
import PhoneInput from '../Inputs/PhoneInput';
import {
  useCreateCustomerAddress,
  useDeleteCustomerAddress,
  useUpdateCustomerAddress,
} from 'apis/CustomersApi';
import { toast } from 'react-toastify';
import { useQueryClient } from '@tanstack/react-query';
import DeleteWarningModal from 'components/common/DeleteWarningModal';
type PropType = {
  customer?: any;
  title?: string;
  viewOnly?: boolean;
};
const CustomerAddressBookCard = (props: PropType) => {
  // ### CONSTANTs
  const queryClient = useQueryClient();
  const { customer, viewOnly, title } = props;
  const { address: addresses } = customer || {};
  const [editingAddress, setEditAddress] = useState<AddressInfo | null>(null);
  const [deletingAddress, setDeleteAddress] = useState<AddressInfo | null>(
    null,
  );
  const isEdit = !!editingAddress?._id;
  const form = useForm({
    validate: yupResolver(
      yup.object({
        f_name: yup.string().required(
          t('form.invalid.required', {
            fieldName: t('register.form.first_name'),
          }),
        ),
        l_name: yup.string().required(
          t('form.invalid.required', {
            fieldName: t('register.form.last_name'),
          }),
        ),
        address: yup.object().required(
          t('form.invalid.required', {
            fieldName: t('register.form.address'),
          }),
        ),
        phone: yup
          .string()
          .test({
            name: 'is-phone-number',
            test(value: any) {
              return validator.isMobilePhone(value || '', undefined, {
                strictMode: false,
              });
            },
          })
          .required(PHONE_NO_REQUIRE_ERROR_MESSAGE),
        type: yup.string().required(),
      }),
    ),
  });
  const { mutate: updateCustomerAddress, isPending: updatingAddress } =
    useUpdateCustomerAddress();
  const { mutate: createCustomerAddress, isPending: creatingAddress } =
    useCreateCustomerAddress();
  const { mutate: deleteCustomerAddress, isPending: deletingAddressLoading } =
    useDeleteCustomerAddress();
  const { getInputProps, getValues, setValues, errors } = form;
  const values = getValues();
  // ### FUNCTIONs

  useEffect(() => {
    setValues({
      ...editingAddress,
      f_name: editingAddress?.f_name || undefined,
      l_name: editingAddress?.l_name || undefined,
      phone: editingAddress?.phone || undefined,
      business_name: editingAddress?.business_name || undefined,
      is_default: editingAddress?.is_default || undefined,
      address: editingAddress?.location ? editingAddress : undefined,
      type: editingAddress
        ? editingAddress?.type || AddressTypeEnum.BUSSINESS
        : undefined,
    });
  }, [editingAddress]);

  const onDeleteAddress = () => {
    deleteCustomerAddress(
      { customer_id: customer?._id, _id: deletingAddress?._id } as any,
      {
        onSuccess: () => {
          toast.success('delete_success');
          queryClient.invalidateQueries(['useCustomerDetails'] as any);
          setDeleteAddress(null);
        },
        onError: (err) => {
          toast.error('delete_fail');
          setDeleteAddress(null);
        },
      },
    );
  };

  const onSaveAdress = async () => {
    await form.validate();
    if (form.isValid()) {
      const submitData = {
        ...values.address,
        f_name: values?.f_name,
        l_name: values?.l_name,
        phone: values?.phone,
        business_name: values?.business_name || (isEdit ? null : undefined),
        type: values?.type,
        zip_code: values?.zip_code ? values?.zip_code : 'NaN',
        customer_id: customer?._id || undefined,
        is_default: values?.is_default || !customer?.address?.length ? 1 : 0,
        _id: editingAddress?._id || undefined,
      };

      // if (isOrder) {
      //   mutate(submitData, {
      //     onSuccess: () => {
      //       setIsEditing(false);
      //       queryClient.invalidateQueries({ queryKey: ['useGetOrders'] });
      //     },
      //   });
      // } else {
      if (!editingAddress?._id) {
        createCustomerAddress(submitData, {
          onSuccess: (res: any) => {
            if (res?.status) {
              toast.success(t('general.create_success'));
              setEditAddress(null);
              queryClient.invalidateQueries(['useCustomerDetails'] as any);
              // onUpdateAddress?.(res?.data?.address?.[0]);
            } else {
              toast.success(t('general.create_fail'));
            }
          },
        });
      } else {
        updateCustomerAddress(submitData, {
          onSuccess: (res: any) => {
            if (res?.status) {
              toast.success(t('general.update_success'));
              queryClient.invalidateQueries(['useCustomerDetails'] as any);
              setEditAddress(null);
              // onUpdateAddress?.(submitData);
              // setIsEditing(false);
            } else {
              toast.success(t('general.update_fail'));
            }
          },
        });
      }
    }
  };

  // ### RENDERs
  const renderEditForm = () => {
    return (
      <form>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <TextInput
              {...getInputProps('f_name')}
              placeholder={t('register.form.first_name')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <TextInput
              {...getInputProps('l_name')}
              placeholder={t('register.form.last_name')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }}>
            <TextInput
              {...getInputProps('business_name')}
              placeholder={t('register.form.business_name_placeholder')}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }}>
            <AddressInput
              onChange={(address) => setValues({ address })}
              address={values?.address}
              error={errors?.address}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }}>
            <PhoneInput
              placeholder="+1 (432) 432-4324"
              defaultValue={editingAddress?.phone}
              onChangeText={(phone: any) => setValues({ phone: phone })}
              error={errors?.phone}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }}>
            <NativeSelect
              {...getInputProps('type')}
              data={[
                { label: t('orders.business_address'), value: 'business' },
                {
                  label: t('orders.residential_address'),
                  value: 'residential',
                },
              ]}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12 }}>
            <Switch
              checked={values?.is_default}
              {...getInputProps('is_default')}
              label={t('general.make_as_default')}
            />
          </Grid.Col>
        </Grid>
      </form>
    );
  };
  return (
    <SectionWrapper>
      <SectionHeader
        title={title || t('orders.address_book')}
        isSubSection
        actionButtons={
          viewOnly ? undefined : editingAddress ? (
            <Flex>
              <Button
                disabled={creatingAddress || updatingAddress}
                size="xs"
                variant="default"
                onClick={() => setEditAddress(null)}
              >
                {t('general.cancel')}
              </Button>
              <Button
                loading={creatingAddress || updatingAddress}
                size="xs"
                className="ms-1"
                onClick={() => onSaveAdress()}
              >
                {t('general.save')}
              </Button>
            </Flex>
          ) : (
            <Button
              onClick={() => setEditAddress({})}
              size="xs"
              variant="default"
            >
              {t('general.add')}
            </Button>
          )
        }
      ></SectionHeader>
      <SectionBody>
        {editingAddress
          ? renderEditForm()
          : addresses?.length
          ? addresses?.map((address: AddressInfo, index: number) => (
              <AddressInfoComponent
                onClickEdit={() => setEditAddress(address)}
                onClickDelete={() => setDeleteAddress(address)}
                className={
                  index < addresses?.length - 1 ? 'mb-3 pb-2 border-b' : ''
                }
                key={index}
                address={address}
              />
            ))
          : '--'}
      </SectionBody>
      <DeleteWarningModal
        name={`${deletingAddress?.f_name} ${deletingAddress?.l_name}`}
        onShow={!!deletingAddress}
        onDelete={onDeleteAddress}
        onClose={() => setDeleteAddress(null)}
        isLoading={deletingAddressLoading}
      />
    </SectionWrapper>
  );
};
export default CustomerAddressBookCard;
