import { Anchor, Flex, Grid, Text } from '@mantine/core';
import { AffiliateItem } from 'types';
import { useAffiliateDetails, useDeleteAffiliate } from 'apis/AffiliateApis';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import PageLayout from 'components/MantineUI/PageLayout';
import { t } from 'i18next';
import CustomerDetailsCard from 'pages/customers/customer-details/components/CustomerDetailsCard';
import React, { ReactElement, ReactNode, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { boolean } from 'yup';
import CopyButton from 'components/MantineUI/Buttons/CopyButton';
import IconFA from 'components/common/IconFA';
import { faGlobe } from '@fortawesome/pro-light-svg-icons';
import { buildFullName, getColor, phoneFormatter } from 'helpers/utils';
import PayoutsCard from './PayoutsCard';
import ReportCard from './ReportCard';
import AddAffiliateModal from './AddAffiliateModal';
import SkeletonWrapper from 'components/MantineUI/Skeleton/SkeletonWrapper';
import SectionPageSkeleton from 'components/MantineUI/Skeleton/SectionPageSkeleton';
import {
  faFacebook,
  faInstagram,
  faTiktok,
  faYoutube,
  IconDefinition,
} from '@fortawesome/free-brands-svg-icons';
import DeleteWarningModal from 'components/common/DeleteWarningModal';

const AffiliateDetails = () => {
  // ### CONSTANTs
  const { id } = useParams();
  const navigate = useNavigate();
  const { data, isLoading } = useAffiliateDetails(id);
  const [selectedAffiliate, setSelectedAffiliate] = useState<
    AffiliateItem | undefined
  >();
  const [deleteAffiliate, setDeleteAffiliate] = useState<
    AffiliateItem | undefined
  >();
  const affiliate: AffiliateItem = data?.data;
  // ### FUNCTIONs
  const { mutate: onDeleteAffiliate, isPending: deleting } =
    useDeleteAffiliate();

  const onDelete = () => {
    onDeleteAffiliate(deleteAffiliate?._id, {
      onSuccess: () => {
        setDeleteAffiliate(undefined);
        navigate('/affiliates');
      },
    });
  };
  // ### RENDERs
  const renderInformation = (
    title: string | ReactElement = '--',
    isCopy: boolean = true,
    link: string = '',
  ) => {
    return (
      <Flex align={'center'} justify={'space-between'}>
        <Text>{title}</Text>
        {isCopy && typeof title === 'string' ? (
          <CopyButton value={title} />
        ) : null}
        {link ? (
          <IconFA
            color={'gray'}
            icon={faGlobe}
            onClick={() => window.open(link, '_blank')}
          />
        ) : null}
      </Flex>
    );
  };
  const title = affiliate?.display_name || '';
  const renderSocialName = (platform: string) => {
    let icon: any = faGlobe;
    let social = platform;
    if (platform.includes('facebook')) {
      icon = faFacebook;
      social = 'Facebook';
    }
    if (platform.includes('instagram')) {
      icon = faInstagram;
      social = 'Instagram';
    }
    if (platform.includes('tiktok')) {
      icon = faTiktok;
      social = 'Tiktok';
    }
    if (platform.includes('youtube')) {
      icon = faYoutube;
      social = 'Youtube';
    }
    return (
      <Flex gap={'xs'}>
        <IconFA icon={icon} />
        <Text>{social}</Text>
      </Flex>
    );
  };
  return (
    <SkeletonWrapper skeleton={<SectionPageSkeleton />} show={isLoading}>
      <PageLayout
        title={title}
        breadCrumds={[
          {
            title: t('affiliates.affiliates'),
            href: '/affiliates',
          },
          {
            title: title,
          },
        ]}
      >
        <Grid>
          <Grid.Col span={{ base: 12, md: 8 }}>
            <CustomerDetailsCard
              customer={{
                ...affiliate,
                profile_image_url: affiliate?.owner?.profile_image_url,
                first_name: affiliate?.display_name,
                last_name: '',
              }}
              customActions={[
                {
                  key: 'edit',
                  label: t('affiliates.edit_affiliate'),
                  onClick: () => setSelectedAffiliate(affiliate),
                },
                { isDivider: true },
                {
                  key: 'delete',
                  color: 'red',
                  label: t('affiliates.delete_affiliate'),
                  onClick: () => setDeleteAffiliate(affiliate),
                },
              ]}
            />
            <ReportCard />
            <PayoutsCard />
          </Grid.Col>
          <Grid.Col span={{ base: 12, md: 4 }}>
            <SectionWrapper>
              <SectionHeader
                isSubSection
                title={t('form.information.information')}
              />
              <SectionBody>
                <Text size="sm" c="dimmed">
                  {t('affiliates.affiliate_code')}
                </Text>
                {renderInformation(affiliate?.code)}
                <Text size="sm" c="dimmed" mt="sm">
                  {t('affiliates.store_link')}
                </Text>
                {renderInformation(
                  'nailzy.com/affiliates/' + affiliate?.slug,
                  false,
                  'nailzy.com/affiliates/' + affiliate?.slug,
                )}
                <Text size="sm" c="dimmed" mt="sm">
                  {t('affiliates.commission_rate')}
                </Text>
                {renderInformation(
                  affiliate?.commission_rate && affiliate?.commission_rate >= 0
                    ? (affiliate?.commission_rate || 0) * 100 + '%'
                    : '--',
                  false,
                )}
                <Text size="sm" c="dimmed" mt="sm">
                  {t('affiliates.extra_discount')}
                </Text>
                {renderInformation(
                  affiliate?.discount && affiliate?.discount >= 0
                    ? affiliate?.discount + '%'
                    : '--',
                  false,
                )}
                <Text size="sm" c="dimmed" mt="sm">
                  {t('affiliates.customer_name')}
                </Text>
                <Anchor
                  component={Link}
                  to={'/customers/' + affiliate?.owner?._id}
                  size="sm"
                >
                  {buildFullName(affiliate?.owner)}
                </Anchor>
                <Text size="sm" c="dimmed" mt="sm">
                  {t('register.form.email')}
                </Text>
                {renderInformation(affiliate?.owner?.email)}
                <Text size="sm" c="dimmed" mt="sm">
                  {t('general.phone')}
                </Text>
                {renderInformation(
                  phoneFormatter(
                    affiliate?.owner?.phone_number ||
                      affiliate?.owner?.business_phone,
                  ),
                )}
                <Text size="sm" c="dimmed" mt="sm">
                  {t('affiliates.social_links')}
                </Text>
                {affiliate?.socials?.map((item, index) => {
                  return renderInformation(
                    renderSocialName(item?.platform),
                    false,
                    item?.url,
                  );
                })}
              </SectionBody>
            </SectionWrapper>
          </Grid.Col>
        </Grid>
        <AddAffiliateModal
          opened={!!selectedAffiliate}
          onClose={() => setSelectedAffiliate(undefined)}
          data={selectedAffiliate}
        />
        <DeleteWarningModal
          name={deleteAffiliate?.display_name}
          onShow={!!deleteAffiliate}
          onClose={() => setDeleteAffiliate(undefined)}
          onDelete={onDelete}
          isLoading={deleting}
        />
      </PageLayout>
    </SkeletonWrapper>
  );
};
export default AffiliateDetails;
