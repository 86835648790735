import { Input, MantineSize } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { useURLParams } from 'hooks/useURLParams';
import { t } from 'i18next';
import React, {
  ChangeEvent,
  ChangeEventHandler,
  ReactNode,
  useState,
} from 'react';
import { useAsyncDebounce } from 'react-table';

type PropType = {
  children?: ReactNode;
  className?: string;
  size?: MantineSize;
  placeholder?: string;
  searchParams?: string;
};
const TableSearchBox = ({
  size = 'xs',
  placeholder = '',
  className = '',
  searchParams = 'search',
}: PropType) => {
  // ### CONSTANTs

  const { params, handleParamChange } = useURLParams() as any;
  const [value, setValue] = useState(params?.[searchParams]);

  // ### FUNCTIONs
  const handleDebounceChange = useAsyncDebounce((value?: string) => {
    handleParamChange(searchParams)(value || undefined);
  }, 500);

  const handleSearchChange = (event?: ChangeEvent<HTMLInputElement>) => {
    const value = event?.target?.value;
    setValue(value);
    handleDebounceChange(value?.trim());
  };
  // ### RENDERs

  return (
    <Input
      className={`grow ${className}`}
      value={value || ''}
      size={size}
      placeholder={placeholder || t('general.search')}
      onChange={handleSearchChange}
      leftSection={<IconFA icon="magnifying-glass" className="text-xs" />}
    />
  );
};
export default TableSearchBox;
