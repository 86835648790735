import { CommonTableColumn, PayoutItem, StatusEnum } from 'types';
import {
  SectionBody,
  SectionHeader,
  SectionWrapper,
} from 'components/MantineUI/CommonSection';
import { t } from 'i18next';
import React, { ReactNode, useState } from 'react';
import { Anchor, Button } from '@mantine/core';
import dayjs from 'dayjs';
import { currencyFormatter, dateTimeFormatter } from 'helpers/utils';
import StatusBadge from 'components/MantineUI/StatusBadge';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import IconFA from 'components/common/IconFA';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
import { CommonTable } from 'components/MantineUI/CommonTable';
import { useAffiliatePayments } from 'apis/AffiliateApis';
import { useParams } from 'react-router-dom';
import PaymentStatus from 'components/common/PaymentStatus';
import UpdatePayoutModal from './UpdatePayoutModal';

const PayoutsCard = () => {
  // ### CONSTANTs
  const { id } = useParams();
  const { data, isLoading } = useAffiliatePayments(id);
  const [selectPayment, setSelectedPayment] = useState<
    PayoutItem | undefined
  >();
  const payouts = data?.data?.results;
  // ### FUNCTIONs
  // ### RENDERs

  const columns: CommonTableColumn[] = [
    {
      accessor: 'payout_period',
      Header: t('payouts.payout_period'),
      Cell: (row: PayoutItem) => (
        <Anchor onClick={() => setSelectedPayment(row)} size="sm">
          {row?.name || '--'}{' '}
        </Anchor>
      ),
    },
    {
      accessor: 'reference',
      Header: t('affiliates.reference'),
      Cell: (row: PayoutItem) => row?.reference || '--',
    },
    {
      accessor: 'earnings',
      Header: t('payouts.earnings'),
      Cell: (row: PayoutItem) =>
        row?.earnings ? currencyFormatter.format(row?.earnings) : '--',
    },
    {
      accessor: 'updated',
      Header: t('general.updated_date'),
      Cell: (row: PayoutItem) => dateTimeFormatter(row?.updated_on) || '--',
    },
    {
      accessor: 'status',
      Header: t('general.status'),
      Cell: (row: PayoutItem) => (
        <PaymentStatus fullWidth status={row?.payment_status} />
      ),
    },
    {
      accessor: 'actions',
      Cell: (row: PayoutItem) => (
        <DropdownButton
          button={
            <Button size="xs" variant="subtle" color="gray">
              <IconFA icon={faEllipsis} />
            </Button>
          }
          dropdownData={[
            {
              key: 'edit',
              label: t('general.edit'),
              onClick: () => setSelectedPayment(row),
            },
          ]}
        />
      ),
    },
    // {
    //   accessor: 'actions',
    //   Cell: () => (
    //     <DropdownButton
    //       button={
    //         <Button size="xs" variant="subtle" color="gray">
    //           <IconFA icon={faEllipsis} />
    //         </Button>
    //       }
    //       dropdownData={[]}
    //     />
    //   ),
    // },
  ];
  return (
    <SectionWrapper>
      <SectionHeader isSubSection title={t('payouts.payouts')}></SectionHeader>
      <SectionBody>
        <CommonTable columns={columns} data={payouts} />
      </SectionBody>
      <UpdatePayoutModal
        data={selectPayment}
        opened={!!selectPayment}
        onClose={() => setSelectedPayment(undefined)}
      />
    </SectionWrapper>
  );
};
export default PayoutsCard;
