import { faCircleQuestion } from '@fortawesome/pro-light-svg-icons';
import { Badge, Flex, Text, Tooltip } from '@mantine/core';
import IconFA from 'components/common/IconFA';
import { getColor } from 'helpers/utils';
import { isNumeric } from 'validator';

type Props = {
  label: string;
  current: number;
  previous: number;
  note: string;
  selected?: boolean;
  format?: (a: any) => string;
};

const ReportItem = ({
  label = '',
  selected,
  current,
  previous,
  note,
  format,
}: Props) => {
  return (
    <div
      className={`border ${selected ? 'border-primary' : ''} rounded-lg p-2`}
    >
      <Flex align={'center'}>
        <Text c={'dimmed'} size="xs" fw={'600'}>
          {label}
        </Text>
        <Tooltip label={note}>
          <div>
            <IconFA
              size="sm"
              icon={faCircleQuestion}
              className={'ms-1 mb-[2px]'}
              color={'#999'}
            />
          </div>
        </Tooltip>
      </Flex>
      <Text fz={20} fw="600">
        {isNumeric(current.toString())
          ? format
            ? format(current)
            : current
          : '--'}
      </Text>
      <Flex gap={'xs'}>
        <Text c={'dimmed'} size="sm">
          {isNumeric(previous.toString())
            ? format
              ? format(previous)
              : previous
            : '--'}
        </Text>
        {!isNumeric(previous.toString()) ||
        !isNumeric(current.toString()) ||
        current === previous ? null : (
          <Badge
            // className="ms-2 fs--2"
            color={previous <= current ? 'green' : 'red'}
            variant="light"
          >
            <Text size="xs">
              <IconFA
                className="me-1"
                icon={`caret-${previous <= current ? 'up' : 'down'}`}
                color={getColor(previous <= current ? 'success' : 'danger')}
              />
              {current === previous
                ? 0
                : current < 0
                ? 100
                : parseFloat(
                    (
                      (Math.abs((previous || 0) - current) /
                        (previous || current)) *
                      100
                    ).toFixed(0),
                  )}
              %
            </Text>
          </Badge>
        )}
      </Flex>
    </div>
  );
};

export default ReportItem;
