import {
  Button,
  Flex,
  Grid,
  InputLabel,
  Modal,
  NumberInput,
  Switch,
  TextInput,
} from '@mantine/core';
import { AffiliateItem, StatusEnum } from 'types';
import { t } from 'i18next';
import SlugInput from 'components/MantineUI/Inputs/SlugInput';
import StatusSelect from 'components/MantineUI/Selects/StatusSelect';
import { SectionFooter } from 'components/MantineUI/CommonSection';
import { Form, useForm } from '@mantine/form';
import { buildSlug } from 'helpers/utils';
import {
  useAffiliateDetails,
  useCreateAffiliate,
  useUpdateAffiliate,
} from 'apis/AffiliateApis';
import { useEffect } from 'react';
import { useQueryClient } from '@tanstack/react-query';
type PropType = {
  opened: boolean;
  onClose: () => void;
  data?: AffiliateItem;
};
const AddAffiliateModal = (props: PropType) => {
  // ### CONSTANTs
  const { opened = false, onClose: onCloseProps, data } = props;
  const isEdit = data?._id;
  const queryClient = useQueryClient();
  // ### FUNCTIONs
  const form = useForm({
    initialValues: {
      display_name: undefined,
      slug: undefined,
      commission_rate: undefined,
      discount: undefined,
      status: StatusEnum.ACTIVE,
      is_discount: false,
    } as AffiliateItem & { is_discount: boolean },
  });

  const onClose = () => {
    form.reset();
    onCloseProps?.();
  };
  const { setValues, getInputProps, getValues, onSubmit } = form;
  const values = getValues();
  const { mutate: create, isPending: creating } = useCreateAffiliate();
  const { mutate: update, isPending: updating } = useUpdateAffiliate();
  const onSubmitForm = () => {
    let submitData = {
      display_name: values?.display_name,
      slug: values?.slug,
      commission_rate: parseFloat(
        ((values?.commission_rate || 0) / 100).toFixed(4),
      ),
      discount: values?.discount || null,
      status: values?.status,
      //   is_discount: values?.is_discount,
    };
    if (isEdit) {
      update(
        { _id: data?._id, ...submitData },
        {
          onSuccess: () => {
            onClose();
            queryClient.invalidateQueries(['useAffiliateDetails'] as any);
          },
        },
      );
    }
  };

  //   const { data: affiliate } = useAffiliateDetails(data?._id);
  useEffect(() => {
    if (data?._id) {
      setValues({
        display_name: data?.display_name,
        slug: data?.slug,
        commission_rate: (data?.commission_rate || 0) * 100,
        discount: data?.discount,
        status: data?.status,
        is_discount: !!(data?.discount && data?.discount > 0),
      });
    }
  }, [data]);
  // ### RENDERs
  return (
    <Modal
      size={'lg'}
      onClose={onClose}
      opened={opened}
      title={t('affiliates.edit_affiliate')}
    >
      <Form form={form} onSubmit={onSubmitForm}>
        <Grid>
          <Grid.Col span={{ base: 12, sm: 12 }}>
            <TextInput
              {...getInputProps('display_name')}
              required
              label={t('affiliates.account_display_name')}
              placeholder="Nails by Nailzzzz"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 12 }}>
            <SlugInput
              key={'slug' + data?._id}
              type={'affiliate_program'}
              defaultValue={data?.slug}
              label={t('affiliates.store_link')}
              placeholder="affiliate-slug"
              prefix="https://nailzy.com/affiliates/"
              value={values?.slug}
              onChange={(event: any) => {
                setValues({
                  slug: buildSlug(event.target.value),
                });
              }}
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <NumberInput
              {...getInputProps('commission_rate')}
              label={t('affiliates.commission_rate')}
              placeholder="3%"
              decimalScale={2}
              min={0}
              max={100}
              suffix="%"
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <InputLabel>{t('affiliates.extra_discount')}</InputLabel>
            <Flex gap={'sm'}>
              <Switch
                checked={values?.is_discount}
                {...getInputProps('is_discount')}
                onChange={(e) =>
                  setValues({
                    is_discount: e.target.checked,
                    discount: e.target.checked ? values?.discount : 0,
                  })
                }
              />
              <NumberInput
                {...getInputProps('discount')}
                placeholder="0%"
                decimalScale={2}
                disabled={!values?.is_discount}
                min={0}
                max={100}
                suffix="%"
              />
            </Flex>
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 6 }}>
            <StatusSelect
              hideDraft
              hidePending={false}
              value={values?.status}
              onChange={(e) =>
                setValues({ status: e.target.value as StatusEnum })
              }
            />
          </Grid.Col>
        </Grid>
        <SectionFooter updatedTime={data?.updated_on}>
          <Button loading={updating} type="submit">
            {t('general.save')}
          </Button>
          <Button variant="default" onClick={() => onClose()}>
            {t('general.cancel')}
          </Button>
        </SectionFooter>
      </Form>
    </Modal>
  );
};
export default AddAffiliateModal;
