const vi = {
  translation: {
    account: {
      account: 'Tài khoản',
      account_name: 'Tên chủ tài khoản',
      account_number: 'Số tài khoản',
      bank_details: 'Thông tin ngân hàng',
      bank_name: 'Ngân hàng',
      change_password: 'Đổi mật khẩu',
      profile_security: 'Hồ sơ & Bảo mật',
      routing_number: 'Mã ngân hàng',
      sales_rep_code: 'Mã nhân viên',
      security: 'Bảo mật',
      security_desc: 'Bạn có thể thêm hoặc thay đổi phương thức đăng nhập.',
      social_links: 'Liên kết mạng xã hội',
      social_links_desc:
        'Liên kết tài khoản với các mạng xã hội để đăng nhập nhanh hơn.',
      store_desc: 'Vui lòng cung cấp thông tin cửa hàng của bạn.',
      store_details: 'Thông tin cửa hàng',
    },
    admin_panel: 'Trang quản trị',
    affiliates: {
      account_display_name: 'Tên hiển thị',
      account_name: 'Tên tài khoản',
      add_affiliate: 'Thêm đối tác TTLK',
      affiliate_code: 'Mã liên kết',
      affiliates: 'Tiếp thị liên kết',
      affiliates_decs:
        'Quản lý và theo dõi các đối tác tiếp thị liên kết, bao gồm tỷ lệ hoa hồng, hiệu suất và các khoản thanh toán.',
      commission_rate: 'Tỷ lệ hoa hồng',
      customer_name: 'Tên khách hàng',
      delete_affiliate: 'Xóa đối tác TTLK',
      edit_affiliate: 'Sửa đối tác TTLK',
      extra_discount: 'Giảm giá thêm',
      reference: 'Tham chiếu',
      social_links: 'Liên kết mạng xã hội',
      store_link: 'Liên kết cửa hàng',
    },
    customer: {
      amount_spent: 'Số tiền đã chi',
      auth_provider: 'Hình thức đăng nhập',
      business_name: 'Tên tiệm',
      business_phone: 'Số điện thoại tiệm',
      create_customer: 'Tạo khách hàng',
      delete_customer: 'Xóa khách hàng',
      delete_customer_note:
        'Lưu ý: Xóa khách hàng này sẽ xóa vĩnh viễn những thông tin và dữ liệu liên quan đến tài khoản của khách.',
      delete_customer_reason: 'Lý do xóa',
      edit_customer: 'Sửa khách hàng',
      export_customer: 'Xuất danh sách khách hàng',
      nail_supply: 'Chủ tiệm supply',
      nail_tech: 'Thợ nail',
      other: 'Khác',
      please_select_add_customer:
        'Vui lòng chọn khách hàng hoặc thêm địa chỉ mới',
      referral_code: 'Mã giới thiệu',
      referrals: 'Giới thiệu',
      referred_by: 'Giới thiệu bởi',
      role_type: 'Loại khách hàng',
      salon_owner: 'Chủ tiệm nail',
      send_email_reset_pass: 'Gửi email khôi phục mật khẩu',
      send_email_reset_pass_fail: 'Gửi email thất bại!',
      send_email_reset_pass_success: 'Gửi email thành công!',
    },
    dashboard: {
      best_seller_items: 'Sản phẩm bán chạy',
      best_selling: 'Sản phẩm bán chạy nhất',
      conversion_rate: 'Tỷ lệ chuyển đổi',
      conversion_rate_decs:
        'Tỷ lệ chuyển đổi từ lượt xem sản phẩm sang đơn hàng',
      custom: 'Tùy chỉnh',
      customer_analytics: 'Thống kê khách hàng',
      dashboard: 'Bảng điều khiển',
      earnings: 'Thu nhập',
      earnings_desc:
        'Tổng số tiền thu nhập thực tính từ những đơn hàng đã hoàn tất',
      ending_coupon: 'Mã giảm giá sắp hết hạn',
      ending_promotion: 'Khuyến mãi sắp kết thúc',
      estimated_commissions: 'Hoa hồng ước tính',
      estimated_commissions_desc:
        'Tổng số tiền hoa hồng ước tính từ những đơn hàng đã thanh toán',
      failed_delivery_order: 'Đơn hàng không giao được',
      greeting: 'Tổng quan về hoạt động hôm nay',
      items_sold: 'Sản phẩm đã bán',
      items_sold_decs: 'Tổng số lượng mặt hàng đã bán',
      last_x_day: '{{days}} ngày gần đây',
      marketing_center: 'Trung tâm tiếp thị',
      need_accept: 'Đơn hàng chờ xác nhận',
      need_approve: 'Đánh giá sản phẩm chờ duyệt',
      need_fulfill: 'Đơn hàng chờ xuất',
      net_sales: 'Doanh thu thuần',
      net_sales_desc: 'Tổng giá trị của những mặt hàng đã bán',
      notifications: 'Thông báo',
      order_analytics: 'Thống kê đơn hàng',
      pending_affiliate: 'Tài khoản TTLK chờ duyệt',
      product_views: 'Lượt xem sản phẩm',
      product_views_decs: 'Tổng số lượng lượt xem các sản phẩm',
      store_visits: 'Lượt truy cập',
      store_visits_decs: 'Số lượng người truy cập vào cửa hàng',
      thing_to_do: 'Việc cần làm',
      today: 'Hôm nay',
      today_orders: 'Đơn hàng hôm nay',
      today_sales: 'Doanh thu hôm nay',
      total_buyer: 'Tổng số khách hàng',
      total_buyer_desc: 'Tổng số khách đã mua hàng',
      total_clicks: 'Tổng lượt nhấp',
      total_clicks_desc: 'Tổng số lượt nhấp vào liên kết tiếp thị',
      total_customers: 'Tài khoản mới',
      total_customers_desc: 'Tổng số tài khoản mới đăng ký',
      total_events: 'Tổng số sự kiện',
      total_events_desc: 'Tổng số sự kiện đã đăng',
      total_jobs: 'Tổng số việc làm',
      total_jobs_desc: 'Tổng số việc làm đã đăng',
      total_orders: 'Tổng đơn hàng',
      total_orders_desc: 'Tổng số đơn hàng đã thanh toán',
      total_paid_orders: 'Đơn hàng đã thanh toán',
      total_paid_orders_desc: 'Tổng số đơn hàng đã thanh toán',
      total_paid_sales: 'Tổng tiền đã thanh toán',
      total_paid_sales_desc: 'Tổng giá trị đơn hàng đã thanh toán',
      total_payouts: 'Lợi  nhuận',
      total_payouts_desc: 'Tổng số tiền bạn đã nhận được',
      total_posts: 'Tổng số bài đăng',
      total_posts_decs: 'Tổng số lượng bài đăng',
      total_potential_buyer: 'Khách hàng tiềm năng',
      total_potential_buyer_desc:
        'Tổng số khách đã đặt hàng nhưng chưa bao giờ thanh toán',
      total_products: 'Tổng số sản phẩm',
      total_products_desc: 'Tổng số sản phẩm đăng bán',
      total_referred_customer: 'Tài khoản giới thiệu',
      total_referred_customer_desc:
        'Tổng số tài khoản mới được giới thiệu bởi người dùng khác',
      total_returning_buyer: 'Khách hàng trở lại',
      total_returning_buyer_desc: 'Tổng số khách đã mua hàng nhiều hơn một lần',
      total_sales: 'Tổng doanh thu',
      total_sales_desc: 'Tổng số tiền hàng đã thanh toán',
      total_salons: 'Tổng số tiệm đăng bán',
      total_salons_desc: 'Tổng số tiệm đã đăng bán',
      total_techs: 'Tổng số hồ sơ thợ',
      total_techs_desc: 'Tổng số hồ sơ thợ đã đăng',
      total_unpaid_orders: 'Tổng số đơn hàng chưa thanh toán',
      total_unpaid_orders_desc:
        'Đơn hàng chưa thanh toán (bao gồm đơn hàng chờ thanh toán, thanh toán thất bại, và chưa thanh toán)',
      total_unpaid_sales: 'Tổng tiền chưa thanh toán',
      total_unpaid_sales_desc:
        'Tổng giá trị đơn hàng chưa thanh toán (bao gồm đơn hàng chờ thanh toán, thanh toán thất bại, và chưa thanh toán)',
      unit_price: 'Đơn giá',
      waiting_payment: 'Đơn hàng chờ thanh toán',
      welcome: 'Xin chào',
    },
    draft_carts: {
      add_draft_carts: 'Thêm giỏ hàng nháp',
      draft_cart: 'Giỏ hàng nháp',
      draft_carts: 'Giỏ hàng nháp',
      save_draft: 'Lưu nháp',
    },
    error: {
      back_home: 'Quay lại trang chính',
      contact_us: 'nhờ trợ giúp.',
      error_403: 'Error 403',
      error_403_content:
        'Bạn không có quyền truy cập vào trang này. Nếu lỗi vẫn xảy ra,',
      error_403_message: 'Truy cập bị từ chối.',
      error_404: 'Lỗi 404',
      error_404_content:
        'Vui lòng kiểm tra lại đường liên kết. Nếu lỗi vẫn xảy ra,',
      error_404_message: 'Trang bạn đang truy cập hiện không tồn tại.',
      error_500: 'Error 500',
      error_500_content: 'Vui lòng thử lại sau. Nếu lỗi vẫn xảy ra,',
      error_500_message: ' Đã có lỗi xảy ra ở đâu đó.',
    },
    filter: {
      filters: 'Bộ lọc',
      joined_date_range: 'Phạm vi ngày đã tham gia',
      role: 'Vai trò',
    },
    forgot_password: {
      a_mail_sent_to_reset_password:
        'Vui lòng kiểm tra hộp thư của bạn để đặt lại mật khẩu',
      create_new: 'và tạo mật khẩu mới.',
      forgot_password: 'Quên mật khẩu',
      login_to: 'đăng nhập vào tài khoản',
      password_reset: 'Mật khẩu đã được đặt lại!',
      please_code: 'Vui lòng nhập 4 chữ số mã xác thực đã được gửi tới email',
      please_enter: 'Vui lòng nhập địa chỉ email của bạn để nhận mã xác thực.',
      reset_password: 'Đặt lại mật khẩu',
      send_code: 'Gửi mã xác thực',
      you_can: 'Giờ bạn có thể',
    },
    form: {
      account: {
        profile_picture: 'Ảnh đại diện',
        security_subtitle:
          'Bạn có thể thêm hoặc cập nhật phương thức đăng nhập của mình.',
        social_linking_subtitle:
          'Đăng nhập nhanh hơn bằng cách liên kết phương tiện truyền thông xã hội với tài khoản của bạn.',
        social_linking_title: 'Liên kết mạng xã hội',
        update_profile_error: 'Lỗi cập nhật hồ sơ',
      },
      change_mail: {
        input_email: 'Nhập email',
        input_email_to_change: 'Nhập email bạn muốn thay đổi',
        input_mail_to_confirm: 'Input your password to confirm',
      },
      change_phone: {
        add_phone_fail: 'Thêm số điện thoại không thành công',
        add_phone_success: 'Thêm số điện thoại thành công',
        code_has_been_sent: 'Mã xác minh đã được gửi đến số điện thoại',
        input_your_phone_number: 'Nhập số điện thoại',
        otp_form_resent_code_after: 'Gửi lại mã sau',
        phone_number_has_been_changed: 'Số điện thoại đã được thay đổi',
        phone_number_you_want_to_change: 'Nhập số điện thoại bạn muốn thay đổi',
        send_otp_error: 'Gửi mã xác minh bị lỗi',
        update_phone_fail: 'Cập nhật số điện thoại không thành công',
        update_phone_success: 'Cập nhật số điện thoại không thành công',
        verify_OTP_code: 'Xác thực mã OTP',
        verify_successful: 'Xác thực thành công',
      },
      common: {
        drop_file_here: 'Thả tập tin của bạn ở đây',
        field_code: 'Mã {{field}}',
        field_logo: 'Logo {{field}}',
        field_name: 'Tên {{field}}',
        field_status: 'Trạng thái {{field}}',
        type: 'Loại',
      },
      coupon: {
        all_customers: 'Tất cả khách hàng',
        all_products: 'Tất cả sản phẩm',
        apply_to: 'Áp dụng cho',
        coupon: 'Mã giảm giá',
        coupon_code: 'Mã giảm giá',
        customer_eligibility: 'Điều kiện của khách hàng',
        discount_value: 'Giá trị giảm',
        excludes: 'Loại trừ',
        excludes_by: 'Loại trừ theo',
        fixed_amount: 'Giá cố định',
        limit: 'Giới hạn',
        minimum_purchase_amount: 'Theo số tiền mua tối thiểu',
        minimum_purchase_requirements: 'Yêu cầu mua tối thiểu',
        multiple_uses_per_customer: 'Nhiều lần sử dụng cho mỗi khách hàng',
        no_exception: 'Không có loại trừ',
        no_target: 'Không có mục tiêu',
        numbers_of_uses: 'Số lần sử dụng',
        one_use_per_customer: 'Một lần sử dụng cho mỗi khách hàng',
        percentage: 'Phần trăm',
        please_select_target: 'Hãy chọn đối tượng cụ thể',
        purchase_quantity: 'Theo số lượng mua tối thiểu',
        quantity: 'Số lượng',
        requirements: 'Yêu cầu',
        selection: 'Lựa chọn mục tiêu',
        specific_brand: 'Nhãn hiệu cụ thể',
        specific_brands: 'Thương hiệu cụ thể',
        specific_cat: 'Danh mục cụ thể',
        specific_cates: 'Danh mục cụ thể',
        specific_customers: 'Khách hàng cụ thể',
        specific_product: 'Sản phẩm cụ thể',
        specific_promotion: 'Khuyến mãi cụ thể',
        specific_seller: 'Người bán cụ thể',
        specific_sellers: 'Người bán cụ thể',
        specific_subcat: 'Danh mục con cụ thể',
        target: 'Mục tiêu',
        target_customer: 'Khách hàng mục tiêu',
        usage_limit: 'Giới hạn sử dụng',
        valid_from: 'Bắt đầu từ',
        valid_until: 'Kết thúc vào',
      },
      information: {
        articles: {
          general_information: 'Thông tin chung',
          offers_promotions: 'Ưu đãi & Khuyến mại',
          orders_shipping: 'Đơn hàng & Vận chuyển',
          payments: 'Thanh toán',
          returns_refunds: 'Trả hàng & Hoàn tiền',
          shopping_with_nailzy: 'Mua hàng cùng Nailzy',
        },
        content: 'Nội dung',
        english: 'Tiếng Anh',
        information: 'Thông tin',
        section: 'Chuyên mục',
        subtitle: 'Tiêu đề phụ',
        title: 'Tiêu đề',
        type: 'Vị trí đặt',
        type_guide: 'Hướng dẫn chọn vị trí đặt',
        type_guide_detail_home_body: 'Thông tin sẽ hiện ở giữa trang chính.',
        type_guide_detail_home_header:
          'Thông tin sẽ hiện ở header (phần trên cùng của website).',
        type_guide_detail_pages:
          'Thông tin sẽ hiện ở header (phần trên cùng của website).',
        type_guide_detail_product_details:
          'Thông tin sẽ hiện ở trang sản phẩm.',
        type_guide_detail_shopping_cart:
          'Thông tin sẽ hiện ở trang giỏ hàng của người dùng.',
        type_guide_home_body: 'Ở phần thân trang chủ',
        type_guide_home_header: 'Ở trên đầu mỗi trang',
        type_guide_pages: 'Trang thông tin',
        type_guide_product_details: 'Trong trang chi tiết sản phẩm',
        type_guide_shopping_cart: 'Mục giỏ hàng',
        vietnamese: 'Tiếng Việt',
      },
      invalid: {
        duplicate_variant_name: 'trùng tên thuộc tính',
        duplicate_variants: '{{name}} không được trùng trong 1 sản phẩm',
        extra_space: '{{name}} có khoảng cách dư hoặc hàng dư',
        greater_than_0: 'phải lớn hơn 0',
        greater_than_equal: 'phải lớn hơn hoặc bằng',
        invalid: '{{fieldName}} không hợp lệ!',
        load_fail: 'Tải {{field}} thất bại!',
        only_allow_number: '{{name}} chỉ được dùng số',
        required: '{{fieldName}} là bắt buộc!',
      },
      store: {
        banner_desc: 'Banner sẽ được hiển thị ở trang cửa hàng của bạn.',
        business_type: 'Loại hình kinh doanh',
        business_type_company: 'Công ty',
        business_type_individual: 'Cá nhân',
        document: 'Tài liệu',
        documents: 'Tài liệu',
        fbn: 'Lưu kho Nailzy (FBN)',
        fulfillment_type: 'Loại lưu kho',
        image_limit_error: 'Number of images is limit to 6',
        legal_business_name: 'Tên doanh nghiêp kinh doanh hợp pháp',
        seller_stocks_and_fulfill: 'Lưu kho người bán',
        store_details: 'Thông tin cửa hàng',
        store_status: 'Trạng thái cửa hàng',
        store_status_desc:
          'Bạn có thể cung cấp tài liệu để xác minh cửa hàng ở đây.',
        tax_id: 'Mã thuế (EIN, ITIN, hoặc SSN)',
        upload_document: 'Tải lên tài liệu',
        upload_images_condition_1: 'Kích thước: 2000 px ngang x 769 px dọc',
        upload_images_condition_2: 'Tối đa: 6 hình, dung lượng 2.0 MB mỗi hình',
        upload_images_condition_3: 'Định dạng hỗ trợ: JPG, PNG, WEPB',
      },
    },
    general: {
      action_required: 'Yêu cầu hành động',
      active: 'Hoạt động',
      add: 'Thêm',
      add_note: 'Thêm ghi chú',
      added: 'Đã thêm',
      all: 'Tất cả',
      apply: 'Áp dụng',
      applying: 'Đang áp dụng...',
      approved: 'Đã duyệt',
      arrange: 'Sắp xếp',
      at: 'lúc',
      back: 'Quay lại',
      banner: 'Banner',
      browse: 'Chọn',
      bulk_actions: 'Thao tác hàng loạt',
      business_name_asc: 'Tên tiệm: A to Z',
      business_name_desc: 'Tên tiệm: Z to A',
      by: 'bởi',
      cancel: 'Hủy',
      canceled: 'Đã hủy',
      cant_undone: '? Thao tác này không thể hoàn lại.',
      change: 'Đổi',
      change_status: 'Đổi trạng thái',
      close: 'Đóng',
      closed: 'Đã đóng',
      code: 'Mã',
      code_asc: '#: A đến Z',
      code_desc: '#: Z đến A',
      completed: 'Hoàn tất',
      confirm: 'Xác nhận',
      content: 'Nội dung',
      continue: 'Tiếp tục',
      copied: 'Đã sao chép',
      copy_failed: 'Sao chép thất bại',
      create: 'Tạo',
      create_fail: 'Tạo thất bại!',
      create_success: 'Tạo thành công!',
      created_asc: 'Ngày tạo: Cũ nhất trước',
      created_by: 'Tạo bởi',
      created_date: 'Ngày tạo',
      created_desc: 'Ngày tạo: Mới nhất trước',
      current: 'Hiện tại',
      days_ago: 'ngày trước',
      default: 'Mặc định',
      delete: 'Xóa',
      delete_confirm: 'Bạn có chắc chắn muốn ',
      delete_confirm_2: 'Bạn có chắc chắn muốn xóa',
      delete_fail: 'Xoá thất bại!',
      delete_success: 'Xoá thành công!',
      deleted: 'Đã xóa',
      deleting: 'Đang xóa...',
      description: 'Mô tả',
      discard: 'Loại bỏ',
      discard_all: 'Loại bỏ thay đổi chưa lưu',
      discard_all_desc:
        'Bạn có chắc chắn muốn loại bỏ những thay đổi chưa được lưu? Dữ liệu mà bạn đã nhập sẽ bị mất.',
      download: 'Tải về',
      draft: 'Nháp',
      drop_files: 'Kéo thả tệp vào đây',
      dropzone_decs_1: 'Kéo thả ảnh hoặc bấm vào đây để chọn',
      dropzone_decs_2:
        'Có thể nhận nhiều file cùng lúc, mỗi file không quá 10mb',
      duplicate: 'Sao chép',
      edit: 'Sửa',
      edit_history: 'Lịch sử chỉnh sửa',
      edit_note: 'Sửa ghi chú',
      end_date: 'Đến',
      error: 'Lỗi',
      export: 'Xuất dữ liệu',
      export_desc: 'Bạn có muốn xuất dữ liệu của',
      export_desc_2: 'ra tệp bảng tính (định dạng XLSX)?',
      export_fail: 'Xuất dữ liệu thất bại!',
      export_success: 'Xuất dữ liệu thành công!',
      exporting: 'Đang xuất...',
      featured: 'Nổi bật',
      filter: 'Bộ lọc',
      first_name_asc: 'Tên: A to Z',
      first_name_desc: 'Tên: Z to A',
      fixed: 'Cố định',
      from: 'từ',
      generate_code: 'Tạo mã',
      hot_brand: 'Thương hiệu hot',
      icon: 'Icon',
      image: 'Hình ảnh',
      images: 'Hình ảnh',
      images_desc: 'hoặc kéo thả ảnh vào đây',
      images_desc_2: 'Tối đa 10 ảnh',
      import: 'Nhập dữ liệu',
      import_desc: 'hàng loạt',
      import_desc_2:
        ' mẫu bảng tính (định dạng XLSX) và điền đầy đủ thông tin cần thiết.',
      import_desc_3:
        'Sau đó bạn có thể tải tệp XLXS của bạn lên để nhập hàng loạt',
      import_fail: 'Nhập dữ liệu thất bại!',
      import_success: 'Nhập dữ liệu thành công!',
      imported: 'Đã nhập',
      importing: 'Đang nhập...',
      invalid_file: 'Tệp lỗi',
      joined_on: 'Đã tham gia',
      last_update_on: 'Cập nhật lần cuối',
      limit: 'Giới hạn',
      link: 'Liên kết',
      loading: 'Đang tải...',
      main_image: 'Hình ảnh chính',
      make_as_default: 'Đặt làm mặt định',
      month_ago: 'tháng trước',
      name: 'Tên',
      name_asc: 'Tên: A đến Z',
      name_desc: 'Tên: Z đến A',
      next: 'Tiếp',
      no_data: 'Chưa có dữ liệu...',
      notes: 'Ghi chú',
      number_sold_asc: 'Lượt bán: Thấp nhất trước',
      number_sold_desc: 'Lượt bán: Cao nhất trước',
      of: 'của',
      official_brand: 'Thương hiệu chính hãng',
      or: 'hoặc',
      page: 'Trang',
      pending: 'Đang chờ',
      percentage: 'Phần trăm',
      phone: 'Số điện thoại',
      previous: 'Trước',
      profile_picture: 'Ảnh đại diện',
      published_date: 'Ngày đăng',
      quantity: 'Số lượng',
      quantity_asc: 'Số lượng: Thấp nhất trước',
      quantity_desc: 'Số lượng: Cao nhất trước',
      rating_asc: 'Đánh giá: Thấp nhất trước',
      rating_desc: 'Đánh giá: Cao nhất trước',
      rejected: 'Đã từ chối',
      remove: 'Bỏ',
      removing: 'Đang bỏ...',
      reorder: 'Sắp xếp',
      reports: 'Báo cáo',
      reset: 'Đặt lại',
      rows_per_page: 'Dòng mỗi trang',
      save: 'Lưu',
      saving: 'Đang lưu...',
      scheduled: 'Đã lên lịch',
      search: 'Tìm kiếm...',
      select: 'Chọn',
      select_all: 'Chọn tất cả',
      select_date_range: 'Chọn khoảng thời gian',
      select_date_time: 'Chọn ngày và giờ',
      select_icon: 'Chọn icon',
      select_order_status: 'Chọn trạng thái đơn hàng',
      select_payment_status: 'Chọn trạng thái thanh toán',
      select_role: 'Chọn chức danh',
      select_type: 'Chọn loại',
      selected: 'đã chọn',
      sent: 'Đã gửi',
      ships_from_nailzy: 'Gửi hàng từ Nailzy',
      slug: 'Slug',
      slug_exist: 'Slug đã tồn tại',
      sort: 'Sắp xếp',
      start_date: 'Từ',
      status: 'Trạng thái',
      team: 'Đội',
      title: 'Tiêu đề',
      to: 'đến',
      to_be_confirmed: 'Cần xác nhận',
      total: 'Tổng cộng',
      type: 'Loại',
      type_here: 'Nhập vào đây...',
      unlink: 'Huỷ liên kết',
      update: 'Cập nhật',
      update_fail: 'Cập nhật thất bại!',
      update_success: 'Cập nhật thành công!',
      updated_asc: 'Ngày cập nhật: Cũ nhất trước',
      updated_date: 'Ngày cập nhật',
      updated_desc: 'Ngày cập nhật: Mới nhất trước',
      upload: 'Tải lên',
      upload_and_save: 'Tải lên và lưu',
      upload_images: 'Tải ảnh lên',
      upload_images_desc:
        'Bạn có thể tải lên nhiều ảnh cùng lúc bằng tệp định dạng ZIP.',
      upload_images_desc_2:
        'Tên tệp ảnh phải tương ứng với dữ liệu của cột "Tên tệp ảnh" trong mẫu nhập dữ liệu hàng loạt.',
      upload_import: 'Tải lên để nhập',
      uploading: 'Đang tải lên...',
      used: 'Đã sử dụng',
      validated: 'Đã xác thực',
      validity_period: 'Thời hạn hiệu lực',
      value: 'Giá trị',
      value_asc: 'Giá trị: Thấp nhất trước',
      value_desc: 'Giá trị: Cao nhất trước',
      verify: 'Xác thực',
      verifying: 'Đang xác thực...',
      view: 'Xem',
      view_less: 'Thu gọn',
      view_more: 'Xem thêm',
      view_web: 'Xem trên web',
      write: 'Viết',
    },
    inquiries: {
      edit_inquiry: 'Sửa khảo sát',
      inquiries: 'Khảo sát',
      inquiries_decs:
        'Xem các yêu cầu và thắc mắc về dịch vụ mà khách hàng quan tâm.',
      inquiry: 'Khảo sát',
    },
    login: {
      admin_login: 'Nhân viên đăng nhập',
      admin_please: 'Vui lòng đăng nhập để truy cập vào trang quản trị.',
      already_seller: 'Đã có tài khoản?',
      back_login: 'Quay lại trang đăng nhập',
      code_sent_to: 'Một mã đã được gửi tới',
      enter_verification_code: 'Vui lòng nhập mã xác nhận',
      forgot_password: 'Quên mật khẩu?',
      login: 'Đăng nhập',
      login_with_password: 'Đăng nhập dùng mật khẩu',
      login_without_password: 'Đăng nhập không cần mật khẩu',
      logout: 'Đăng xuất',
      no_account: `Chưa có tài khoản bán hàng?`,
      or_continue_with: 'Hoặc tiếp tục bằng',
      register: 'Đăng ký',
      register_free: 'Đăng ký miễn phí',
      seller_login: 'Người bán đăng nhập',
      seller_please: 'Vui lòng đăng nhập để truy cập vào trang bán hàng.',
      signed_out: 'Đã đăng xuất',
      thank_you: 'Cảm ơn bạn đã sử dụng dịch vụ của Nailzy!',
    },
    navigation: {
      add_cart: 'Thêm giỏ hàng',
      add_coupon: 'Thêm mã giảm giá',
      announcements: 'Thông báo',
      articles: 'Bài viết',
      banner_details: 'Chi tiết banner',
      banners: 'Banners',
      cart_details: 'Chi tiết giỏ hàng',
      carts: 'Giỏ hàng',
      coupon: 'Mã giảm giá',
      coupon_details: 'Chi tiết mã giảm giá',
      coupons: 'Mã giảm giá',
      current: 'Hiện tại',
      customer_details: 'Chi tiết khách hàng',
      customers: 'Khách hàng',
      discount_details: 'Chi tiết giảm giá',
      discounts: 'Giảm giá',
      edit_banner: 'Sửa banner',
      edit_cart: 'Sửa giỏ hàng',
      edit_coupon: 'Sửa mã giảm giá',
      edit_discount: 'Sửa giảm giá',
      event_signups: 'Đăng ký khóa học',
      events: 'Khóa học',
      faqs: 'Câu hỏi thường gặp',
      flash_sale: 'Giảm giá siêu tốc',
      help_articles: 'Bài viết hỗ trợ',
      help_center: 'Hỗ trợ',
      home: 'Trang chính',
      information: 'Thông tin',
      jobs: 'Việc làm',
      just_arrived: 'Hàng mới về',
      language: 'English',
      pages: 'Trang',
      postings: 'Bài đăng',
      posts: 'Bài viết',
      promotion: 'Khuyến mãi',
      promotions: 'Khuyến mãi',
      salons: 'Tiệm cần bán',
      seller_details: 'Chi tiết người bán',
      sellers: 'Người bán',
      settings: 'Thiết lập',
      shipping: 'Vận chuyển',
      skills: 'Kỹ năng',
      staff: 'Nhân viên',
      staff_details: 'Chi tiết nhân viên',
      stock_clearance: 'Hàng thanh lý',
      taxes: 'Thuế',
      techs: 'Hồ sơ thợ',
      top_picks: 'Hàng chuẩn',
      user_details: 'Chi tiết người dùng',
      users: 'Người dùng',
      warehouses: 'Kho hàng',
    },
    orders: {
      abandoned_checkout: 'Đơn hàng bị bỏ',
      accept: 'Xác nhận',
      accept_order: 'Tiếp nhận đơn hàng',
      accept_shipping: 'Xác nhận giao hàng',
      actions: 'Thao tác',
      add_multi_package: 'Thêm thùng vận chuyển',
      address_book: 'Địa chỉ',
      affiliate_discount: 'Giảm giá TTLK',
      all_sellers: 'Tất cả người bán',
      already_bought_label: 'Bạn đã mua nhãn vận chuyển này vào',
      already_chose_label: 'Bạn đã chọn mức giá vận chuyển này vào',
      already_entered_custom: 'Bạn đã nhập đóng hàng tùy chỉnh bên dưới vào',
      already_entered_tracking_info: 'Bạn đã nhập thông tin mã vận đơn này vào',
      amount: 'Số tiền',
      awaiting_fulfillment: 'Đang chờ đóng hàng',
      awaiting_payment: 'Chờ thanh toán',
      bad_reviews: 'Nhận xét không tốt',
      better_price: 'Tìm được giá tốt hơn',
      billing_address: 'Địa chỉ thanh toán',
      business_address: 'Công ty',
      business_day: 'ngày làm việc',
      business_days: 'ngày làm việc',
      buy_shipping_label: 'Mua nhãn vận chuyển',
      cancel_and_refund: 'Huỷ đơn và hoàn tiền',
      cancel_order: 'Hủy đơn hàng',
      canceled: 'Đã hủy',
      change_of_mind: 'Thay đổi ý định',
      change_status: 'Đổi trạng thái',
      choose_shipping_rate: 'Chọn mức giá vận chuyển',
      completed: 'Đã hoàn tất',
      coupon_discount: 'Mã giảm giá',
      current_page: 'Trang hiện tại',
      custom_fulfillment: 'Đóng hàng tùy chỉnh',
      custom_note: 'Ghi chú',
      customer: 'Khách hàng',
      customer_request: 'Khách hàng yêu cầu',
      customer_selected_method: 'Khách đã chọn loại này lúc thanh toán',
      discount: 'Giảm giá',
      discounts: 'Giảm giá',
      dup_coupon_code: 'Nhân bản mã giảm giá',
      duplicate_order: 'Đơn hàng trùng lặp',
      edit_custom_fulfillment: 'Sửa đóng hàng tùy chỉnh',
      edit_history: 'Lịch sử chỉnh sửa',
      edit_order: 'Sửa đơn hàng',
      edit_shipping: 'Sửa thông tin mã vận đơn',
      edit_shipping_information: 'Chỉnh sửa thông tin vận chuyển',
      edit_shipping_rate: 'Sửa mức giá vận chuyển',
      edit_tracking_info: 'Chỉnh sửa thông tin mã vận đơn',
      enter_custom_fulfillment: 'Nhập đóng hàng tùy chỉnh',
      enter_order_note: 'Nhập ghi chú đơn hàng',
      enter_tracking_info: 'Nhập thông tin mã vận đơn',
      est_delivery: 'Dự kiến giao hàng',
      expensive_shipping: 'Chi phí vận chuyển quá cao',
      export_orders: 'Xuất đơn hàng',
      fetching_pricing: 'Đang tính toán tổng tiền...Vui lòng đợi!',
      fetching_shipment: 'Đang tìm mức giá vận chuyển...Vui lòng đợi!',
      flat_rate: 'Giá cố định',
      forgot_coupon: 'Quên áp dụng mã giảm giá',
      fraud_analysis: 'Phân tích gian lận',
      free_shipping: 'Miễn phí vận chuyển',
      fulfill_order: 'Xuất đơn hàng',
      fulfill_type: 'Loại lưu kho',
      fulfilling_shipment: 'Đang  đơn hàng...Xin vui lòng đợi!',
      fulfillment_status: 'Trạng thái đóng hàng',
      get_payment_receipt: 'Lấy hóa đơn thanh toán',
      grand_total: 'Tổng tiền',
      high_risk: 'Rủi ro cao',
      item: 'Món',
      item_discount: 'Giảm giá sản phẩm',
      item_total: 'Tổng giá sản phẩm',
      items: 'Món',
      items_discount: 'Giảm giá sản phẩm',
      items_name: 'Sản phẩm',
      items_total: 'Tổng giá sản phẩm',
      long_shipping: 'Thời gian vận chuyển quá lâu',
      manage_package: 'Quản lý thùng mẫu',
      mark_as_delivered: 'Đánh dấu đơn hàng là đã giao',
      marketplace: 'Mua sắm',
      mass: 'Khối lượng',
      minimum_place_order: 'Đơn đặt hàng tối thiểu là $0.5',
      msrp_discount: 'Giảm giá MSRP',
      nailzy_coupon: 'Mã giảm giá Nailzy',
      no_discount: 'Không có giảm giá',
      no_order: 'Chưa có đơn hàng...',
      normal_risk: 'Rủi ro bình thường',
      order: 'Đơn hàng',
      order_all: 'Tất cả',
      order_canceled: 'Đã huỷ',
      order_comments: 'Ghi chú đơn hàng',
      order_completed: 'Đã hoàn tất',
      order_date: 'Ngày đặt hàng',
      order_date_range: 'Khoảng thời gian',
      order_delivered: 'Đã giao hàng',
      order_details: 'Chi tiết đơn hàng',
      order_failed_delivery: 'Giao hàng thất bại',
      order_mistake: 'Đặt hàng nhầm',
      order_number: 'Đơn hàng #',
      order_partially_shipped: 'Đã chuyển một phần',
      order_pending: 'Chờ tiếp nhận',
      order_process: 'Tiến độ đơn hàng',
      order_processing: 'Đang xử lý',
      order_shipped: 'Đang vận chuyển',
      order_status: 'Trạng thái đơn hàng',
      order_success: 'Đặt hàng thành công',
      order_without_paying: 'Tạo đơn hàng và thanh toán sau',
      orders: 'Đơn hàng',
      other: 'Khác',
      out_of_stock: 'Hết hàng',
      package: 'Thùng',
      package_and_weight: 'Chi tiết thùng',
      package_info: 'Chi tiết thùng',
      packing_list_will_print:
        'Danh sách đóng gói sẽ được in ngay sau khi bấm xác nhận',
      paid: 'Đã thanh toán',
      partially_paid: 'Thanh toán một phần',
      partially_shipped: 'Đã giao hàng một phần',
      pay_with_credit: 'Thanh toán bằng thẻ',
      payment_error: 'Lỗi thanh toán',
      payment_failed: 'Thanh toán thất bại',
      payment_information: 'Thông tin thanh toán',
      payment_issue: 'Vấn đề thanh toán',
      payment_method: 'Phương thức thanh toán',
      payment_status: 'Trạng thái thanh toán',
      payment_success: 'Thanh toán thành công',
      pending: 'Chưa xử lý',
      please_fetch_data: 'Vui lòng lấy dữ liệu',
      please_provide_shipping: 'Vui lòng cung cấp thông tin vận chuyển',
      please_select_shipping: 'Vui lòng chọn phương thức vận chuyển',
      pos_coupon: 'Mã giảm giá POS',
      print_invoice: 'In hóa đơn',
      print_packing_list: 'In danh sách đóng gói',
      print_shipping_label: 'In nhãn vận chuyển',
      process_order: 'Xử lý đơn hàng',
      product: 'Sản phẩm',
      products: 'Sản phẩm',
      promotion_discount: 'Giảm giá khuyến mãi',
      provider: 'Provider',
      quantity: 'Số lượng',
      reason: 'Lý do',
      refulfill_order: 'Xuất hàng lại',
      refund: 'Hoàn tiền',
      refunded: 'Đã hoàn tiền',
      residential_address: 'Nhà riêng',
      sales: 'Bán hàng',
      sales_tax: 'Thuế',
      select_coupons: 'Chọn mã giảm giá',
      select_customer: 'Chọn khách hàng',
      select_items: 'Chọn {{total}} sản phẩm',
      select_shipment: 'Chọn loại đóng hàng',
      select_status: 'Chọn trạng thái',
      seller_coupon: 'Mã giảm giá người bán',
      send_noti_to_customer: 'Gửi thông báo đến khách hàng',
      send_order_status: 'Gửi trạng thái đơn hàng qua SMS',
      send_payment_link: 'Gửi link cho khách thanh toán',
      send_payout: 'Gửi thanh toán',
      shipment: 'Loại vận chuyển',
      shipment_custom: 'Đã nhập đóng hàng tùy chỉnh',
      shipment_manual: 'Đã nhập thông tin mã vận đơn',
      shipment_shippo: 'Đã mua nhãn vận chuyển',
      shipped: 'Đã giao hàng',
      shipping: 'Vận chuyển',
      shipping_address: 'Địa chỉ giao hàng',
      shipping_carrier: 'Hãng vận chuyển',
      shipping_information: 'Thông tin vận chuyển',
      shipping_label: 'Nhãn vận chuyển',
      shipping_method: 'Phương thức vận chuyển',
      shipping_rates: 'Mức giá vận chuyển',
      size: 'Kích thước',
      sku: 'Mã sản phẩm',
      source_admin: 'Trang quản trị',
      source_app: 'Ứng dụng di động',
      source_from: 'Nguồn đặt hàng',
      source_web: 'Trang web',
      store: 'Cửa hàng',
      subtotal: 'Tạm tính',
      summary: 'Tóm tắt',
      sync_tracking_status: 'Đồng bộ hóa trạng thái theo dõi',
      taxes: 'Thuế',
      total: 'Tổng cộng',
      total_pricing: 'Tổng cộng',
      total_weight_with_package: 'Trọng lượng (cả bao bì)',
      tracking_number: 'Mã vận đơn',
      unit_price: 'Đơn giá',
      unpaid: 'Chưa thanh toán',
      variant: 'Lựa chọn',
      view_order: 'Xem đơn hàng',
      void_custom_fulfillment: 'Hủy đóng hàng tùy chỉnh',
      void_information: 'Hủy thông tin mã vận đơn',
      void_label: 'Hủy nhãn vận chuyển',
      write_comment: 'Viết ghi chú',
      wrong_address: 'Sai địa chỉ giao hàng',
      you_want_accept_order: 'Bạn muốn tiếp nhận đơn hàng #{{code}} này?',
      you_want_cancel_order: 'Bạn muốn huỷ đơn hàng #{{code}} này?',
    },
    packages: {
      add_package: 'Thêm gói hàng',
      default_package: 'Gói hàng mặc định',
      default_package_label: 'Dùng để tính phí vận chuyển khi thanh toán.',
      edit_package: 'Sửa gói hàng',
      height: 'Cao',
      length: 'Dài',
      name: 'Tên',
      package: 'Gói hàng',
      package_name: 'Tên gói hàng',
      packages: 'Gói hàng',
      packages_decs: 'Mẫu thùng định sẵn dùng để lúc mua nhãn vận chuyển.',
      weight: 'Cân nặng',
      width: 'Rộng',
    },
    payouts: {
      balance: 'Số dư',
      balance_details: 'Số dư theo đơn hàng',
      balance_seller: 'Người bán',
      completed_date: 'Ngày hoàn tất',
      earnings: 'Thu nhập',
      finances: 'Tài chính',
      net: 'Số tiền nhận được',
      no_payout: 'Chưa có thanh toán...',
      paid: 'Đã thanh toán',
      paid_date: 'Ngày thanh toán',
      pay: 'Thanh toán',
      payout: 'Thanh toán',
      payout_details: 'Thanh toán cho đơn hàng',
      payout_period: 'Kỳ thanh toán',
      payouts: 'Thanh toán',
      platform_fee: 'Phí sử dụng nền tảng 10%',
      transaction_fee: 'Phí giao dịch 3%',
      transaction_id: 'Mã giao dịch',
      update_payout: 'Sửa thanh toán',
    },
    products: {
      activity: 'Hoạt động',
      add_product: 'Thêm sản phẩm',
      add_variant: 'Thêm lựa chọn khác',
      allow_purchase: 'Cho phép mua',
      arrange_variants: 'Sắp xếp lựa chọn',
      availability: 'Hiển thị',
      availability_desc:
        'Để ẩn lựa chọn này trên cửa hàng của bạn, bỏ chọn "Hiển thị trên cửa hàng".',
      available_to_buy: 'Hiển thị trên cửa hàng',
      banner: 'Banner',
      barcode: 'Mã vạch',
      brand: 'Thương hiệu',
      brand_desc:
        'Chọn GENERIC nếu sản phẩm không có thương hiệu. Vui lòng liên hệ với chúng tôi nếu bạn muốn thêm thương hiệu.',
      brands: 'Thương hiệu',
      brands_not_found: 'Thương hiệu không tìm thấy',
      build_import_subtitle:
        'Tải lên nhiều sản phẩm cùng lúc bằng cách điền thông tin chi tiết sản phẩm vào tệp bảng tính.',
      bulk_import: 'Nhập hàng loạt',
      bulk_update: 'Cập nhật hàng loạt',
      categories: 'Danh mục',
      categories_not_found: 'Danh mục không tìm thấy',
      category: 'Danh mục',
      correction: 'Sửa chữa',
      count: 'Sai số lượng',
      created_user: 'Người dùng đã tạo',
      damage: 'Hư hại',
      date: 'Ngày',
      delete_product: 'Xóa sản phẩm',
      dimensions: 'Kích thước',
      dimensions_lwh: 'Kích thước (Dài, Rộng, Cao)',
      download_template: 'Tải xuống bản mẫu',
      edit_product: 'Sửa sản phẩm',
      export: 'Xuất dữ liệu',
      export_import: 'Xuất & Nhập dữ liệu',
      export_product_desc:
        'Sử dụng mẫu có sẵn để cập nhật hàng loạt thông tin sản phẩm một cách hiệu quả.',
      file_id: 'ID tệp',
      file_is_uploaded: 'Tệp đã được tải lên',
      file_name: 'Tên tệp',
      generate_export: 'Tạo mẫu xuất dữ liệu',
      import: 'Nhập dữ liệu',
      import_id: 'ID nhập',
      import_inventory: 'Số lượng kho',
      import_pricing: 'Giá cả',
      import_product_desc_1:
        'Kích thước tối đa: 5 MB ở định dạng XLSX, XLS, hoặc CSV.',
      import_product_desc_2:
        'Vui lòng tải lên mẫu mới nhất để tránh lỗi khi nhập dữ liệu.',
      import_product_desc_3:
        'Vui lòng không cập nhật thông tin sản phẩm khi quá trình nhập dữ liệu chưa hoàn tất.',
      import_status: 'Trạng thái nhập',
      inventory: 'Kho hàng',
      inventory_adjustment: 'Điều chỉnh hàng tồn kho',
      inventory_count: 'Số lượng hàng trong kho',
      inventory_desc: `Để cập nhật thông tin hàng tồn kho: "Số lượng hàng trong kho"`,
      inventory_low_level: 'Mức hàng tối thiểu',
      inventory_low_level_desc: `Hiển thị thông báo "Nhanh tay nào! Chỉ còn X món..." khi số lượng hàng trong kho dưới mức này. Mặc định là 5 nếu không điền.`,
      material: 'Chất liệu',
      no_category: 'Chưa có danh mục..',
      no_information: 'Chưa có thông tin..',
      no_product: 'Chưa có sản phẩm...',
      no_promotion: 'Chưa có khuyến mãi...',
      no_sub_category: 'Chưa có danh mục con..',
      order_canceled: 'Đơn hàng đã hủy',
      order_created: 'Đã tạo đơn hàng',
      original_price: 'Giá gốc',
      original_price_desc: `Để hiển thị giá giảm, điền giá gốc của sản phẩm vào mục "Giá gốc". Điền giá đã giảm vào mục "Giá bán".`,
      other: 'Khác',
      price: 'Giá bán',
      pricing: 'Giá cả',
      pricing_desc: `Để cập nhật thông tin giá sản phẩm: "Giá bán" và "Giá gốc"`,
      product: 'Sản phẩm',
      product_descriptions: 'Mô tả sản phẩm',
      product_details: 'Chi tiết sản phẩm',
      product_id: 'Mã sản phẩm',
      product_name: 'Tên sản phẩm',
      product_reviews: 'Đánh giá sản phẩm',
      product_slug: 'Slug',
      product_status: 'Trạng thái sản phẩm',
      product_variants: 'Lựa chọn sản phẩm',
      products: 'Sản phẩm',
      purchase_limit: 'Giới hạn mua hàng',
      purchase_limit_desc:
        'Giới hạn số lượng mỗi khách hàng có thể mua trong một khoảng thời gian nhất định',
      purchase_limit_per_type_day: 'mỗi ngày',
      purchase_limit_per_type_month: 'mỗi tháng',
      purchase_limit_per_type_week: 'mỗi tuần',
      quantity: 'Số lượng',
      ratings: 'Đánh giá',
      reason: 'Lý do',
      returns: 'Hoàn trả',
      select_brand: 'Chọn thương hiệu',
      select_category: 'Chọn danh mục',
      select_seller: 'Chọn người bán',
      select_store: 'Chọn cửa hàng',
      select_subcategory: 'Chọn danh mục con',
      sellers_not_found: 'Người bán không tìm thấy',
      sellers_with_multi_warehouse: 'Người bán có nhiều kho hàng',
      sellers_without_warehouse: 'Người bán không có kho hàng',
      shipping: 'Vận chuyển',
      similar_products: 'Sản phẩm tương tự',
      size: 'Kích cỡ',
      sku: 'Mã sản phẩm',
      sold: 'Đã bán',
      start_import_flow: 'Bắt đầu nhập dữ liệu',
      stock: 'Số hàng tồn',
      stock_item_activity_correction: 'Điều chỉnh',
      stock_item_activity_count: 'Đếm hàng',
      stock_item_activity_damage: 'Hư hỏng',
      stock_item_activity_initial_inventory: 'Hàng tồn kho ban đầu',
      stock_item_activity_other: 'Khác',
      stock_item_activity_returns: 'Trả hàng',
      stock_item_activity_transfer: 'Chuyển kho',
      stock_item_type_add: 'Thêm',
      stock_item_type_deduct: 'Bớt',
      stock_item_type_update: 'Cập nhật',
      store: 'Cửa hàng',
      stores: 'Cửa hàng',
      subcategories: 'Danh mục con',
      subcategories_not_found: 'Danh mục con không tìm thấy',
      subcategory: 'Danh mục con',
      template: 'Mẫu',
      transfer: 'Vận chuyển',
      upload_file: 'Tải tệp lên',
      validated_success: 'Xac thực thành công',
      validated_with_errors: 'Có lỗi khi xác thực',
      variant: 'Lựa chọn',
      variant_barcode: 'Mã vạch (UPC, GTIN, ISBN, v.v.)',
      variant_description: 'Mô tả lựa chọn',
      variant_id: 'Mã loại',
      variant_name: 'Tên lựa chọn',
      variant_sku: 'Mã sản phẩm (SKU)',
      variant_slug: 'Slug lựa chọn',
      variants: 'Lựa chọn',
      visible_marketplace: 'Hiển thị trên mục Mua sắm',
      warehouse: 'Kho hàng',
      weight: 'Cân nặng',
      weight_desc: 'Dùng để tính phí vận chuyển.',
    },
    promotions: {
      add_banner: 'Tạo banner',
      add_sale: 'Tạo giảm giá',
      all_selected_promotions: 'Tất cả sản phẩm đã chọn',
      apply_time: 'Thời gian áp dụng',
      arrange_banners: 'Sắp xếp banner',
      available_stock: 'Tồn kho',
      banner: 'Banner',
      banner_desc:
        'Tạo quảng cáo bắt mắt bằng banner giúp gia tăng sự chú ý cho người mua.',
      bonus_gift: 'Quà tặng kèm',
      bonus_gift_desc:
        'Tặng quà kèm theo sản phẩm giúp tăng giá trị sản phẩm và thu hút khách hàng.',
      combo: 'Combo khuyến mãi',
      combo_desc:
        'Tạo gói sản phẩm bao gồm nhiều sản phẩm khác nhau được bán với giá ưu đãi.',
      coupon_code: 'Mã giảm giá',
      coupon_code_desc:
        'Mã giảm giá giúp tăng doanh số bán hàng và thu hút khách hàng mới.',
      create_promotion: 'Tạo khuyến mãi mới',
      customer_can_buy_any: 'Mỗi khách có thể mua tối đa',
      delete_item: 'Xóa',
      description: 'Mô tả',
      discount: 'Giảm giá siêu tốc',
      discount_amount: 'Số tiền giảm',
      discount_desc:
        'Tăng doanh số thông qua chương trình khuyến mãi giảm giá siêu tốc.',
      discount_price: 'Giá giảm',
      edit_promotion: 'Sửa khuyến mãi',
      end_date: 'Kết thúc vào',
      existing_variants: 'Sản phẩm đã có ở khuyến mãi khác',
      find_by_brand: 'Tìm theo thương hiệu',
      find_by_cate: 'Tìm theo danh mục',
      find_by_name: 'Tìm theo tên hoặc mã',
      find_by_seller: 'Tìm theo người bán',
      for_new_user: 'Cho người dùng mới',
      item_discount: 'Giảm giá sản phẩm',
      item_discount_desc:
        'Thu hút sự chú ý của người mua bằng cách giảm giá sản phẩm.',
      item_per_order: 'Giới hạn',
      max_purcharse_product: 'Giới hạn mua hàng',
      original_price: 'Giá gốc',
      product_list: 'Danh sách sản phẩm',
      product_s: 'sản phẩm',
      promotion_name: 'Tên chương trình',
      promotion_sku: 'Số món',
      promotion_slug: 'Slug',
      remove_items: 'Xóa {{items}} sản phẩm',
      save_and_select_product: 'Lưu và nhập sản phẩm',
      save_info: 'Lưu thông tin',
      select_products: 'Chọn sản phẩm',
      selected_products: 'Món đã chọn',
      selling_price: 'Giá đang bán',
      shipping_discount: 'Giảm giá vận chuyển',
      shipping_discount_desc:
        'Giảm bớt chi phí cho khách hàng, từ đó khuyến khích họ mua sắm nhiều hơn.',
      start_date: 'Bắt đầu từ',
      status: 'Trạng thái',
      status_type: 'Phân loại',
      sub_title: 'Tiêu đề phụ',
      title: 'Tiêu đề',
      total_item: 'Số lượng',
      type: 'Loại',
    },
    push_notifications: {
      message: 'Nội dung',
      push_notifications: 'Thông báo đẩy',
      send_notification: 'Gửi thông báo',
      title: 'Tiêu đề',
    },
    register: {
      after_that: `Sau đó chúng tôi sẽ thu`,
      and: 'và',
      and_only: 'và chỉ phải chịu',
      become_our: 'Trở thành đối tác',
      calculate: 'Cách tính phí bán hàng',
      contact: 'Liên hệ hỗ trợ bán hàng',
      create_a: 'Đăng ký tài khoản bán hàng',
      customer: 'Hỗ trợ chăm sóc khách hàng',
      customer_content:
        'Dịch vụ hỗ trợ và chăm sóc khách hàng xuất sắc, với đội ngũ tận tâm với nền tảng kiến thức sâu luôn sẵn sàng giúp đỡ người bán.',
      discount: 'Giảm giá',
      each_order: 'cho mỗi đơn hàng. ',
      easy: 'Dễ sử dụng',
      easy_content:
        'Hệ thống giúp quản lý sản phẩm và xử lý đơn hàng mọi lúc mọi nơi trên bất kỳ thiết bị nào với giao diện đơn giản và dễ sử dụng.',
      fast: 'Tham gia nhanh và miễn phí',
      fast_content:
        'Chúng tôi đem đến một giải pháp tiện lợi để mở tiệm bán hàng trực tuyến trên Nailzy, với cách thức đăng ký hoàn toàn miễn phí và dễ dàng.',
      for_your: 'trong 3 tháng đầu tiên bán hàng trên sàn Nailzy!',
      form: {
        account_registration: 'Tài khoản',
        address: 'Địa chỉ',
        back: 'Quay lại',
        business_email: 'Email doanh nghiệp',
        business_name_placeholder: 'Tên tiệm, tên cửa hàng',
        business_type: 'Loại hình kinh doanh',
        company: 'Công ty',
        confirm_new_password: 'Xác nhận mật khẩu mới',
        confirm_password: 'Xác nhận mật khẩu',
        create_account: 'Tạo tài khoản',
        current_password: 'Mật khẩu hiện tại',
        department: 'Phòng',
        didnt_otp: `Không nhận được mã?`,
        done: 'Hoàn tất',
        email: 'Email',
        email_existed: 'Email đã tồn tại',
        email_verification: 'Xác thực email',
        first_name: 'Tên',
        individual: 'Cá nhân',
        last_name: 'Họ',
        legal_business_name: 'Tên pháp lý công ty',
        mobile: 'Di động',
        new_password: 'Mật khẩu mới',
        our_team:
          'Nhân viên của Nailzy sẽ sớm liên lạc với bạn qua điện thoại hoặc email để giúp bạn hoàn tất thủ tục đăng ký tài khoản. Hãy chờ nhé!',
        password: 'Mật khẩu',
        phone: 'Điện thoại',
        please_otp: 'Vui lòng nhập 4 chữ số mã xác thực đã được gửi tới email',
        read_agree: `Tôi xác nhận đã đọc và đồng ý với`,
        register_content: `Vui lòng cung cấp thông tin tài khoản của bạn.`,
        resend_otp: 'Gửi lại',
        seller_agreement: 'Chính sách bán hàng',
        seller_information: 'Thông tin người bán',
        store_address: 'Địa chỉ cửa hàng',
        store_email: 'Email cửa hàng',
        store_logo: 'Logo cửa hàng',
        store_name: 'Tên cửa hàng',
        store_phone: 'Điện thoại cửa hàng',
        tax_id: 'Mã số thuế (EIN ITIN, hoặc SSN)',
        thank_you: 'Cảm ơn bạn đã tham gia bán hàng cùng Nailzy!',
        verification_code: 'Mã xác thực',
        verify_email: 'Xác thực email',
      },
      free: 'MIỄN PHÍ',
      how_to_sell: 'Các bước để bán hàng trên sàn Nailzy',
      increased: 'Mức hiển thị và độ tiếp cận cao',
      increased_content:
        'Đăng bán hàng trên Nailzy giúp tăng khả năng hiển thị và tiếp cận đến nhiều đối tượng khách hàng chủ chốt của ngành nail.',
      marketing: 'Tiếp thị & quảng cáo',
      marketing_content:
        'Tăng doanh số bán hàng qua các chương trình khuyến mãi và quảng cáo để tiếp cận với nhiều đối tượng khách hàng.',
      net_sales: 'Doanh thu ròng',
      order_total: 'Tổng tiền hàng',
      platform: '10% phí sử dụng nền tảng',
      platform_fee: 'Phí sử dụng nền tảng 10%',
      sales_tax: 'Thuế',
      sell_on: 'Mở tiệm bán hàng trên sàn Nailzy.com',
      seller_account: 'chỉ với vỏn vẹn',
      selling_partner: 'bán hàng của Nailzy',
      shipping: 'Vận chuyển',
      simple: '3 bước đơn giản',
      step_1: 'Mở tài khoản bán hàng',
      step_1_1: 'Điền thông tin tài khoản',
      step_1_2: 'Cung cấp các giấy tờ kinh doanh',
      step_1_3: 'Được chấp thuận và bắt đầu bán hàng',
      step_2: 'Đăng sản phẩm',
      step_2_1: 'Đăng từng sản phẩm',
      step_2_2: 'Đăng sản phẩm hàng loạt',
      step_3: 'Quản lý đơn hàng',
      step_3_1: 'Nhận email thông báo khi có đơn hàng mới',
      step_3_2: 'Xử lý đơn hàng và in nhãn vận chuyển',
      step_3_3: 'Giao hàng cho đơn vị vận chuyển',
      step_4: 'Nhận thanh toán',
      step_4_1: 'Nhận báo cáo doanh thu hàng tháng',
      step_4_2: 'Nhận thanh toán qua chuyển khoản hoặc chi phiếu',
      subtotal: 'Tiền hàng',
      the_fees:
        'Những phí này sẽ được tính dựa trên tổng doanh thu của bạn sau khi đã trừ đi các khoản giảm giá, tiền vận chuyển, và thuế nếu có.',
      there_are: 'Bạn sẽ',
      to_sell: 'để bắt đầu đăng sản phẩm và nhận thanh toán.',
      transaction: '3% phí giao dịch',
      transaction_fee: 'Phí giao dịch 3%',
      unique: 'Tính năng độc đáo',
      unique_content:
        'Ứng dụng Nailzy cung cấp nhiều tính năng cho nghề nail như tìm việc, tuyển thợ, sang nhượng tiệm, hoặc lớp dạy nail.',
      why_sell: 'Tại sao bán nên bán hàng cùng Nailzy?',
      why_sell_content:
        'Nailzy.com là siêu ứng dụng cung cấp các giải pháp tối ưu cho ngành nail: giúp tiệm tìm thợ, thợ tìm việc, và sang nhượng tiệm nail nhanh chóng. Sàn thương mại điện tử Nailzy được ví như là Amazon của ngành nail, đem đến cho các nhà bạn một nền tảng bán hàng hiện đại và tiện lợi nhất.',
      your_payout: 'Số tiền bạn nhận',
      zero_fees: 'KHÔNG bị tính phí sử dụng',
    },
    reviews: {
      acceptable: 'Tạm ổn',
      add_review_success: 'Đánh giá thành công',
      delivery_packaging: 'Chất lượng sản phẩm',
      dissatisfied: 'Chưa hài lòng',
      edit_review: 'Sửa đánh giá',
      enthusiastic_seller: 'Người bán hàng',
      good: 'Khá tốt',
      other_reason: 'Khác',
      product_quality: 'Chất lượng sản phẩm',
      rating: 'Đánh giá',
      reasonable_price: 'Giá cả sản phẩm',
      review: 'Nội dung',
      reviews_count: 'lượt đánh giá',
      satisfied: 'Hài lòng',
      tags: 'Thẻ',
      very_satisfied: 'Rất hài lòng',
    },
    seller_panel: 'Trang bán hàng',
    sellers: {
      add_seller: 'Thêm người bán',
      completed_orders: 'Đơn hàng hoàn thành',
      completed_orders_decs: 'Tổng đơn hàng đã hoàn thành',
      completed_sales: 'Doanh thu thực tế',
      completed_sales_decs:
        'Tổng doanh thu đã ghi nhận theo đơn hàng hoàn thành',
      create_warehouse: 'Tạo kho hàng',
      delete_seller: 'Xóa cửa hàng',
      edit_bank: 'Sửa thông tin ngân hàng',
      edit_seller: 'Sửa hồ sơ người bán',
      edit_store: 'Sửa thông tin cửa hàng',
      edit_warehouse: 'Sửa kho hàng',
      no_seller_found: 'Không tìm thấy người bán!',
      seller: 'Người bán',
      seller_profile: 'Hồ sơ người bán',
      seller_selected: 'người bán đã chọn',
      store_address: 'Địa chỉ cửa hàng',
      total_orders: 'Tổng đơn hàng',
      total_orders_decs: 'Tổng số lượng đơn hàng đã đặt',
      total_sales: 'Doanh thu dự kiến',
      total_sales_decs: 'Tổng giá trị đơn hàng đã đặt',
      warehouse: 'Kho hàng',
    },
    shipping: {
      add_flat_rate: 'Thêm mức cố định',
      amount: 'Mức giá',
      amount_estimated: 'Mức giá & ngày nhận hàng dự kiến',
      base_on_price: 'Tính theo giá trị đơn hàng',
      base_on_weight: 'Tính theo trọng lượng đơn hàng',
      calculated: 'Sẽ được tính',
      carrier_name: 'Tên đơn vị vận chuyển',
      condition_pricing: 'Giá theo điều kiện',
      conditions: 'Điều kiện',
      confirm_update_shipping_rate: 'Bạn muốn đổi mức phí vận chuyển sang ',
      countries_and_states: 'Quốc gia & tiểu bang/tỉnh',
      delete_shipping_rate: 'Xóa phí vận chuyển',
      estimated_days: 'Ngày nhận hàng dự kiến',
      flat_rate: 'Mức cố định',
      flat_rate_desc: 'Tính một mức giá vận chuyển cố định khi thanh toán.',
      live_rate: 'Mức tự động',
      live_rate_desc:
        'Phí vận chuyển lúc thanh toán sẽ được tự động tính bởi dịch vụ của bên thứ ba.',
      manage_shipping_zones: 'Quản lý vùng vận chuyển',
      max_price: 'Giá tối đa',
      max_price_must_grater_than_min:
        'Giá tối đa không được nhỏ hơn giá tối thiểu',
      max_weight: 'Trọng lượng tối đa',
      max_weight_must_grater_than_min:
        'Cân nặng tối đa không được nhỏ hơn cân nặng tối thiểu',
      min_price: 'Giá tối thiểu',
      min_price_must_grater_than_0: 'Giá tối thiểu phải lớn hơn 0',
      min_weight: 'Trọng lượng tối thiểu',
      min_weight_must_grater_than_0: 'Cân nặng tối thiểu phải lớn hơn 0',
      rate_name: 'Tên mức',
      shipping_desc:
        'Thiết lập phí vận chuyển lúc thanh toán và quản lý vùng vận chuyển.',
      shipping_rates: 'Mức phí vận chuyển',
      shipping_zones: 'Vùng vận chuyển',
      shipping_zones_desc: 'Nơi đơn hàng có thể được giao đến.',
      states: 'Tiểu bang',
      update_flat_rate: 'Cập nhật mức cố định',
      update_shipping_rate: 'Đổi phí vận chuyển',
    },
    skills: {
      add_skill: 'Thêm kỹ năng',
      no_skill_found: 'Không tìm thấy kỹ năng!',
      skill: 'Kỹ năng',
      skill_name: 'Tên kỹ năng',
      skill_selected: 'kỹ năng được lựa chọn',
    },
    system_users: {
      action_delete: 'XÓA',
      action_get: 'XEM',
      action_post: 'TẠO',
      action_put: 'SỬA',
      add_role: 'Thêm vai trò',
      add_system_user: 'Thêm người dùng',
      edit_role: 'Sửa vai trò',
      edit_system_user: 'Sửa người dùng',
      is_super_admin: 'Quản lí cấp cao?',
      permissions: 'Phân quyền',
      role_and_permission: 'Vai trò & phân quyền',
      role_name: 'Tên vài trò',
      roles: 'Vai trò',
      roles_decs:
        'Quản lý phân quyền người dùng hệ thông bằng cách cho phép hoặc từ chối truy cập các chức năng cụ thể.',
      system_users: 'Người dùng hệ thống',
      system_users_decs: 'Quản lí vai trò và phân quyền cho người dùng nội bộ.',
    },
    warehouses: {
      inventory_quantity: 'Số lượng hàng tồn kho',
      page_sub_title:
        'Quản lý nơi bạn dự trữ hàng tồn kho, xử lý đơn hàng, và bán sản phẩm.',
      product_variant: 'Tổng số lựa chọn sản phẩm',
      warehouse_address: 'Địa chỉ',
      warehouse_name: 'Tên kho',
      warehouse_status: 'Tình trạng kho',
    },
  },
};

export default vi;
