import { faCheck } from '@fortawesome/pro-solid-svg-icons';
import { Text, TextInput } from '@mantine/core';
import { useCheckSlug } from 'apis/ProductsApi';
import IconFA from 'components/common/IconFA';
import { t } from 'i18next';
import React, { useEffect, useState } from 'react';
import { useAsyncDebounce } from 'react-table';

type Props = {
  type?: any;
  value?: string;
  prefix?: string;
  onChange?: (event: any) => void;
  errorMessage?: any;
  fieldName?: string;
  placeholder?: string;
  defaultValue?: string;
  required?: boolean;
  label?: string;
};

const SlugInput = ({
  type = 'product',
  value,
  prefix,
  onChange,
  defaultValue,
  label,
  errorMessage,
  placeholder,
  required,
}: Props) => {
  const { mutate: checkSlug }: any = useCheckSlug();
  const [defaultSlug, setDefaultSlug] = useState<string>();
  const [isExist, setIsExist] = useState<boolean>();
  useEffect(() => {
    if (!defaultSlug && defaultValue) {
      setDefaultSlug(defaultValue);
    }
  }, [defaultValue, defaultSlug]);
  useEffect(() => {
    if (value && value !== defaultSlug) {
      onValidate(value);
    }
  }, [value, defaultSlug]);
  const onValidate = useAsyncDebounce(async (value) => {
    // const res =await

    if (value === defaultSlug) {
      setIsExist(false);
    } else {
      checkSlug(
        { slug: value, model: type },
        {
          onSuccess: (res: any) => {
            setIsExist(!res?.data);
          },
        },
      );
    }
  }, 500);

  return (
    <TextInput
      label={label || t('general.slug')}
      leftSection={
        <Text className="ms-2" size="sm" c={'neutral.5'}>
          {prefix}
        </Text>
      }
      leftSectionWidth={(prefix?.length || 0) * 7.3}
      value={value}
      required={required}
      placeholder={placeholder}
      error={isExist ? t('general.slug_exist') : errorMessage || ''}
      rightSection={
        value && !isExist ? <IconFA icon={faCheck} color="green" /> : null
      }
      onChange={onChange}
    ></TextInput>
  );
};
export default SlugInput;
