import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StrictModeDroppable from 'components/common/StrictModeDroppable';
import CardDropdown from 'components/common/CardDropdown';
import FalconLightBox from 'components/common/FalconLightBox';
import NailzyImage from 'components/common/NailzyImage';
import { getColor } from 'helpers/utils';
import { t } from 'i18next';
import React, { useCallback, useEffect, useState } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { Button, Image } from '@mantine/core';
import DropdownButton from 'components/MantineUI/Buttons/DropdownButton';
import IconFA from 'components/common/IconFA';
import { faEllipsis } from '@fortawesome/pro-solid-svg-icons';
const IMG_URL = process.env.REACT_APP_PRODUCT_URL;

const DragDropImages = ({
  data,
  onChange,
  draggableId = 'draggableId',
  containerClassName,
  handleRemoveImage,
  getRootProps,
  getInputProps,
  direction = 'vertical',
}) => {
  const [draggableData, setDraggableData] = useState([
    { id: draggableId, contents: data || [] },
  ]);
  useEffect(() => {
    setDraggableData([{ ...draggableData?.[0], contents: data }]);
  }, [data]);

  const getColumn = (id) => {
    return draggableData.find((item) => item.id === id);
  };

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const move = (source, destination) => {
    const sourceItemsClone = [...getColumn(source.droppableId).contents];
    const destItemsClone = [...getColumn(destination.droppableId).contents];

    const [removedItem] = sourceItemsClone.splice(source.index, 1);
    destItemsClone.splice(destination.index, 0, removedItem);

    return {
      updatedDestItems: destItemsClone,
      updatedSourceItems: sourceItemsClone,
    };
  };

  const onDragEnd = (result) => {
    const { source, destination } = result;

    if (!destination) {
      return;
    }

    if (source.droppableId === destination.droppableId) {
      const items = getColumn(source.droppableId).contents;
      const reorderedItems = reorder(items, source.index, destination.index);
      const result = draggableData.map((item) =>
        item.id === source.droppableId
          ? { ...item, contents: reorderedItems }
          : item,
      );

      setDraggableData(result);
      onChange?.(reorderedItems?.map((x) => x.value)?.filter((x) => !!x));
    }
  };

  const renderImage = (file, index) => {
    return (
      <div>
        <div
          style={{
            width: '80px',
            display: 'flex',
            aspectRatio: 1,
            borderRadius: 5,
            alignItems: 'center',
            justifyContent: 'center',
            borderWidth: 1,
            borderStyle: 'dashed',
            borderColor: getColor('gray-300'),
            marginRight: '10px',
          }}
        >
          {typeof file === 'string' ? (
            <NailzyImage
              width={'100%'}
              className="border border-dashed"
              image_url={file}
              lightBox
            />
          ) : (
            <FalconLightBox
              image={
                file?.media_url
                  ? `${IMG_URL}/${file?.media_url}`
                  : file?.preview
              }
            >
              <Image
                onClick={null}
                rounded
                width={'100%'}
                src={
                  file?.media_url
                    ? `${IMG_URL}/${file?.media_url}`
                    : file?.preview
                }
                alt={file?.path}
                className="border border-dashed"
              />
            </FalconLightBox>
          )}
        </div>
        <DropdownButton
          button={
            <Button size="xs" variant="subtle" color="neutral.7">
              <IconFA icon={faEllipsis} />
            </Button>
          }
          dropdownData={[
            {
              key: 'remove',
              label: t('general.remove'),
              color: 'red',
              onClick: () => handleRemoveImage(index),
            },
          ]}
        />
      </div>
    );
  };
  const renderEmptyImage = () => (
    <div
      {...getRootProps({
        style: {
          width: '80px',
          display: 'flex',
          aspectRatio: 1,
          borderRadius: 5,
          alignItems: 'center',
          justifyContent: 'center',
          borderWidth: 1,
          borderStyle: 'dashed',
          borderColor: getColor('gray-300'),
          marginRight: '10px',
        },
      })}
    >
      <FontAwesomeIcon className="text-secondary fs-1" icon="image" />
    </div>
  );
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <StrictModeDroppable
        direction={direction}
        droppableId={draggableId}
        type="DRAG"
      >
        {(provided) => (
          <div
            ref={provided.innerRef}
            {...provided.droppableProps}
            style={{ display: 'flex' }}
          >
            <input {...getInputProps()} />
            {draggableData?.[0]?.contents?.map((item, index) => (
              <Draggable
                key={item._id}
                draggableId={`drag${item._id}`}
                index={index}
              >
                {(provided) => {
                  return (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                    >
                      {item?.value ? (
                        renderImage(item?.value, index)
                      ) : (
                        <div key={index}>{renderEmptyImage()}</div>
                      )}
                    </div>
                  );
                }}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </DragDropContext>
  );
};
export default DragDropImages;
