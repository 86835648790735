import { HTTPMethods } from './api.type';
import { AddressInfo, StatusEnum } from './common.type';

export enum UserRole {
  SYSTEM_USER = 'system_user',
  SELLER = 'seller',
}

export type UserInfo = {
  _id?: string;
  name?: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  email?: string;
  phone_number?: string;
  business_phone?: string;
  logo?: string;
  address?: AddressInfo;
  department?: string;
  profile_image_url?: string;
  type?: UserRole;
  role?: UserRole;
};
export type SystemUserItem = {
  _id?: string;
  first_name?: string;
  last_name?: string;
  email?: string;
  phone_number?: string;
  address?: AddressInfo;
  department?: string;
  status?: StatusEnum;
  permission_id?: string;
  password?: string;
  confirm_password?: string;
  created_on?: number;
  updated_on?: number;
};

export type PermissionItem = {};

export type RoleItem = {
  _id?: string;
  name?: string;
  total_system_users?: number;
  system_users?: UserInfo[];
  created_on?: number;
  updated_on?: number;
  total_permissions?: number;
  status?: StatusEnum;
};
export type PermissionActionItem = {
  name?: string;
  actions?: PermissionAction[];
};
export type PermissionAction = {
  path?: string;
  methods?: HTTPMethods[];
};
